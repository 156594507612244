import { Grid, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import {
  InsuraceCardBody,
  InsuraceCardFooter,
  InsuraceFlexBox,
  InsuraceStatusForActive,
  InsuraceStatusForInActive,
  InsuraceTitle,
  InsuranceCard,
  InsuranceCardWrapper,
  InsuranceHeader,
  InsuranceIconContainer,

} from "./PolicyStyle";
import EditPolicyPopOver from "./EditPolicyPopOver";

const item=  {
  policyName:'Auto Insurance',
  policyStatus:'ACTIVE',
  policyNumber:'abcd1242424',
  fullPremium:1200,
  effectiveDate:'20/04/24',
  expirationDate:'23/12/24',
  companyName:'Insurance',
  policyLink:'https://hrm.orangetoolz.com/',
  agentName:'Bond-007'
}
const EachInsurance = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openEditPolicyPopOver = Boolean(anchorEl);
  const id = openEditPolicyPopOver ? "simple-popover" : undefined;
  return (
    <InsuranceCard>
      <InsuranceCardWrapper>
        <InsuranceHeader>
          <InsuraceFlexBox>
            <InsuraceTitle>{item.policyName}</InsuraceTitle>
            {item.policyStatus === "ACTIVE" ? (
              <InsuraceStatusForActive>ACTIVE</InsuraceStatusForActive>
            ) : (
              <InsuraceStatusForInActive>INACTIVE</InsuraceStatusForInActive>
            )}
          </InsuraceFlexBox>
          <InsuraceFlexBox>
            <Typography  style={{ fontWeight: 300,fontSize:"14px" }}>
              Policy &nbsp;: &nbsp;{item.policyNumber}
            </Typography>
            <Typography style={{fontSize:"14px"}}>Full Premium&nbsp;:&nbsp; ${item.fullPremium}</Typography>
          </InsuraceFlexBox>
        </InsuranceHeader>
        <InsuraceCardBody>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography style={{ color: "#929da9",fontSize:'11px',wordBreak:'break-word' }}>EFFECTIVE DATE</Typography>
              <Typography style={{ fontWeight: 500,fontSize:'11px' }}>{item.effectiveDate}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ color: "#929da9",fontSize:'11px',wordBreak:'break-word' }}>EXPIRATION DATE</Typography>
              <Typography style={{ fontWeight: 500,fontSize:'11px' }}>{item.expirationDate}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ color: "#929da9",fontSize:'11px',wordBreak:'break-word' }}>INSURANCE COMPANY</Typography>
              <Typography style={{ fontWeight: 500,fontSize:'11px' }}>{item.companyName}</Typography>
            </Grid>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <a href={item.policyLink}>
                <LinkIcon style={{ rotate: "135deg", fontSize: "25px" }} />
              </a>
            </Grid>
          </Grid>
        </InsuraceCardBody>
        <InsuraceCardFooter>
          <InsuraceFlexBox>
            <Typography style={{ fontWeight: 500,fontSize:'14px' }}>AGENT</Typography>
            <Typography style={{ fontWeight: 500,fontSize:'14px' }}>{item.agentName}</Typography>
          </InsuraceFlexBox>
        </InsuraceCardFooter>
      </InsuranceCardWrapper> 
      <InsuranceIconContainer onClick={handleOpenPopOver} >
        <MoreVertIcon />
      </InsuranceIconContainer>
      {openEditPolicyPopOver && (
        <EditPolicyPopOver open={openEditPolicyPopOver} id={id} handleClose={handleClosePopOver} anchorEl={anchorEl} />
      )}
    </InsuranceCard>
  );
};

export default EachInsurance;
