import React from "react";
import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import { LEAD_TYPES } from "../../../constants/CoreConstants";
import {
  AddContactModalV2Styles,
  BasicInput,
  BasicInputSelect,
} from "./AddContactModalV2Styles";

const LeadType = ({ leadType, onChange }) => {
  const { menuItem } = AddContactModalV2Styles();
  const placeholderColor = "rgb(8 40 82 / 44%)";
  const activeColor = "#36454F";

  return (
    <Box>
      <FormControl fullWidth sx={{ marginLeft: 2 }}>
        <Select
          value={leadType || "default"}
          defaultValue={"default"}
          name="leadType"
          onChange={(event) => onChange("lead_type", event.target.value)}
          input={<BasicInputSelect   style={{ color: `${!leadType ? placeholderColor : activeColor}` }}/>}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="default" disabled className={`dropdownhelper-menuitem-class ${menuItem}`}>
            Lead Type
          </MenuItem>

          {LEAD_TYPES.map((leadType) => (
            <MenuItem
              key={leadType.value}
              value={leadType.value}
              className={`dropdownhelper-menuitem-class ${menuItem}`}
            >
              {leadType.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LeadType;
