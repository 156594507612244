import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { iconList } from "../../constants/iconList";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationCount,
  incrementNotificationCount,
  setSiteNotificationLoaded,
} from "../../actions/notificationAction";
import moment from "moment-timezone";
import Utils from "../../helpers/Utils";
import SidebarPopoverMenu from "./SidebarPopoverMenu";
import CardMultiStepForm from "../DirectCardSend/CardMultiStepForm";
import Helper from "../DirectCardSend/assets/Helper";
// import SidebarCustomMenu from "./SidebarCustomMenu";
import { Button, Chip } from "@material-ui/core";
import {
  getAgencyTwilioSurChargeApi,
  viewUserMapFeatureApi,
} from "../../api/globalApi";
import { storeInitialStates } from "../DirectCardSend/redux/cardBroadcastAction";
import { useIsMounted } from "../../hooks/useIsMounted";
import useDelayCallback from "../../hooks/useDelayCallback";
import Styles from './Sidebar.module.css';
import SidebarNewCustomMenu from "./SidebarNewCustomMenu";

const Sidebar = (props) => {
  const CUSTOM_MENU_1_TITLE = 68;
  const CUSTOM_MENU_1_LINK = 69;
  const CUSTOM_MENU_1_ICON = 70;
  const CUSTOM_MENU_1_COLOR = 77;
  const CUSTOM_MENU_1_BG = 78;
  let hiddenSection = "";
  const isTempUser = Utils.getAccountData("isTemplateUser");
  if (isTempUser == 1) {
    hiddenSection =
      hiddenSection +
      "side-inbox side-deals side-calendar side-contacts side-find-leads side-reporting side-video";
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const isMount = useIsMounted();
  const [activeSidebar, setActiveSidebar] = useState("");
  const { inboxCount, notificationCount, siteNotificationsLoaded } =
    useSelector((state) => state.notificationReducer);

  const [directMailInfos, setDirectMailInfos] = useState({ open: false });

  const [showTwilioSurChargeReport, setShowTwilioSurChargeReport] =
    useState(false);
  const [showMapFeatureOption, setShowMapFeatureOption] = useState(false);

  useEffect(() => {
    try {
      if (Utils.getAccountData("agencyTwilioSurCharge") !== undefined) {
        if (Utils.getAccountData("agencyTwilioSurCharge") > 0) {
          setShowTwilioSurChargeReport(true);
        }
      } else {
        getAgencyTwilioSurChargeApi().then((res) => {
          let response = res.data;
          if (response.status === "success") {
            if (response.data > 0) {
              setShowTwilioSurChargeReport(true);
            }
          }
        });
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    if (Utils.getAccountData("agencyId") !== 1) {
      viewUserMapFeatureApi()
        .then((res) => {
          let response = res.data;

          if (
            response.status === "success" &&
            response.isUserMapFeatureView === true
          ) {
            setShowMapFeatureOption(true);
          } else {
            setShowMapFeatureOption(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // if (Utils.getAccountData('userId') === 10471) {
      //   setShowMapFeatureOption(false);

      // } else {
      setShowMapFeatureOption(true);

      // }
    }

    let customMenu = Utils.getAccountData("customMenu");
    let menuCustomColor = "#FFFFFF";
    let menuCustomBackground = "#3a3a3a";
    if (customMenu) {
      if (
        customMenu[CUSTOM_MENU_1_TITLE] &&
        customMenu[CUSTOM_MENU_1_LINK] &&
        customMenu[CUSTOM_MENU_1_ICON]
      ) {
        menuCustomColor = customMenu[CUSTOM_MENU_1_COLOR]
          ? customMenu[CUSTOM_MENU_1_COLOR]
          : "#FFFFFF";
        menuCustomBackground = customMenu[CUSTOM_MENU_1_BG]
          ? customMenu[CUSTOM_MENU_1_BG]
          : "#3a3a3a";
      }
    }
  }, []);

  useDelayCallback(() => {
    if (isMount) {
      if (props.getUserSettingData) {
        props.getUserSettingData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSidebar]);

  window.openGlobalDirectMailModal = (info) => {
    // info.messageType = 11;

    props.storeInitialStates();
    let [
      contactDetails,
      messageType,
      directMailCallBack,
      directMailContactId,
      editableCampaign,
      directMailFrom,
      directMailCampaign,
    ] = [null, "", null, "", null, "", null];
    if (info.messageType) {
      if (info.contactDetails) {
        if (addressCheck(info.contactDetails)) {
          contactDetails = info.contactDetails;
          messageType = info.messageType;

          if (info.callBack !== undefined && info.callBack != null) {
            directMailCallBack = info.callBack;
          }

          if (info.contactId !== undefined && info.contactId != null) {
            directMailContactId = info.contactId;
          }

          if (
            info.editableCampaign !== undefined &&
            info.editableCampaign != null
          ) {
            editableCampaign = info.editableCampaign;
          }

          if (info.from !== undefined && info.from != null) {
            directMailFrom = info.from;
          }
        } else {
          window.showNotification("ERROR", "Contact has no address");
        }
      } else {
        messageType = info.messageType;

        if (info.callBack) {
          directMailCallBack = info.callBack;
        }

        if (info.contactId) {
          directMailContactId = info.contactId;
        }

        if (info.editableCampaign) {
          editableCampaign = info.editableCampaign;
        }

        if (info.from) {
          directMailFrom = info.from;
        }

        if (info.directMailCampaign) {
          directMailCampaign = info.directMailCampaign;
        }
      }

      // CdnFiles('',["asset/css/releases/1.0.0/global-direct-mail1.0.1.css"])
      setDirectMailInfos({
        open: true,
        contactDetails: contactDetails,
        messageType: messageType,
        directMailCallBack: directMailCallBack,
        directMailContactId: directMailContactId,
        editableCampaign: editableCampaign,
        directMailFrom: directMailFrom,
        directMailCampaign: directMailCampaign,
      });
    } else {
      window.showNotification("ERROR", " No directMail Type selected");
    }
  };

  window.getShowMapFeatureOption = () => {
    return showMapFeatureOption;
  };

  const addressCheck = (contactInfos) => {
    if (Helper.isEmpty(contactInfos.address)) {
      return false;
    }
    try {
      if (contactInfos.address.trim === "") {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
    return true;
  };
  //To active menu from child modules
  window.setActiveSidebar = (module) => {
    setActiveSidebar(module);
  };

  //To update the sidebar on inbox click
  window.updateNotificationCount = (increment = false, live = false) => {
    if (!increment || inboxCount <= 10) {
      if (live) {
        dispatch(fetchNotificationCount({ needInboxCountLive: true }));
      } else {
        dispatch(fetchNotificationCount());
      }
    } else if (notificationCount <= 10) {
      dispatch(incrementNotificationCount());
    }

    if (siteNotificationsLoaded) {
      dispatch(setSiteNotificationLoaded(false));
    }
  };

  window.getUserCurrentTimezone = () => {
    return Utils.getAccountData("userTimezoneRaw");
  }

  window.globalTimezoneConversionToDifferentTimezone = (
    inputTime,
    currentTimezone = "UTC",
    convertTimezone = "",
    format = "MM-DD-YYYY HH:mm:ss",
    returnCurrentDateWhenError = true
  ) => {
    try {
      if (convertTimezone === "") {
        convertTimezone = Utils.getAccountData("userTimezoneRaw");
      }
      if (format === "") {
        format = "MM-DD-YYYY HH:mm:ss";
      }
      var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
      if (format === "from_now") {
        return currentTimeInGivenTimezone.clone().tz(convertTimezone).fromNow();
      }
      return currentTimeInGivenTimezone
        .clone()
        .tz(convertTimezone)
        .format(format);
    } catch (e) {
      if (!returnCurrentDateWhenError) {
        return ""
      }
      return moment(new Date()).format(format);
    }
  };

  //Same url, should not render again and again.
  const gotoUrlAndAvoidRerendering = (e, url, component, tab = null) => {
    e.preventDefault();
    setActiveSidebar(component);
    if (url !== window.location.pathname) {
      history.push(url, tab);
    }
  };

  const callBackGlobalDirectMailSend = (data) => {
    if (
      directMailInfos.directMailCallBack !== undefined &&
      directMailInfos.directMailCallBack != null
    ) {
      directMailInfos.directMailCallBack(data);
    }

    setDirectMailInfos({ open: false });
  };

  const setViewCardModal = (boolean) => {
    if (!boolean) {
      setDirectMailInfos({ open: false });
    }
  };
  const goToDealSalesReportLink = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/user/products",
      state: { from: "settingPage", tab: 7 },
    });
    window.scrollTo(0, 0);
  };
  return (
    <aside
      id="sidenav-left"
      className="awesome__scroll_bar  bg_dark_blue main__sidebar box-shadow-none bg_ds_side_bar"
    >
      {directMailInfos.open && (
        <CardMultiStepForm
          onClose={() => {
            setViewCardModal(false);
          }}
          from={
            directMailInfos.directMailFrom !== ""
              ? directMailInfos.directMailFrom
              : "inbox"
          }
          callBack={callBackGlobalDirectMailSend}
          directMailInfos={directMailInfos}
        />
      )}
      <ul className="aside__nav">
        <BootstrapTooltip
          title={props.menuCollapsed ? "Dashboard" : ""}
          arrow
          placement="right"
        >
          <li>
            <Link
              to="/"
              className={
                "waves-effect left-side-main-menu " +
                (activeSidebar === "dashboard" ? "active" : "")
              }
              onClick={() => setActiveSidebar("dashboard")}
            >
              {iconList.Dashboard}
              <span className="item__text txt_ds_side_bar">Dashboard</span>
            </Link>
          </li>
        </BootstrapTooltip>

        {hiddenSection.search("side-inbox") == -1 && (
          <BootstrapTooltip
            title={props.menuCollapsed ? "Inbox" : ""}
            arrow
            placement="right"
          >
            <li>
              <Link
                to="/inbox"
                className={
                  "waves-effect left-side-main-menu" +
                  (activeSidebar === "inbox" ? " active" : "")
                }
                onClick={() => setActiveSidebar("inbox")}
              >
                <svg
                  className="flex-shrink-0 icon_ds_side_bar"
                  width="18"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.0282 12C18.0282 12.338 17.8967 12.6197 17.6338 12.8451C17.4085 13.0704 17.1268 13.1831 16.7887 13.1831H4.78873L0 18.0282V1.1831C0 0.84507 0.112676 0.56338 0.338028 0.338028C0.56338 0.112676 0.84507 0 1.1831 0H16.7887C17.1268 0 17.4085 0.112676 17.6338 0.338028C17.8967 0.56338 18.0282 0.84507 18.0282 1.1831V12ZM22.8169 4.78873C23.1549 4.78873 23.4366 4.90141 23.662 5.12676C23.8873 5.35211 24 5.6338 24 5.97183V24L19.2113 19.2113H5.97183C5.6338 19.2113 5.35211 19.0986 5.12676 18.8732C4.90141 18.6479 4.78873 18.3662 4.78873 18.0282V15.6056H20.3944V4.78873H22.8169Z"></path>
                </svg>
                <span className="item__text txt_ds_side_bar">Inbox</span>
                {inboxCount !== 0 && (
                  <span className="inbox-sidebar__count">
                    {inboxCount > 10 ? "10+" : inboxCount}
                  </span>
                )}
              </Link>
            </li>
          </BootstrapTooltip>
        )}

        <BootstrapTooltip
          title={props.menuCollapsed ? "Deals" : ""}
          arrow
          placement="right"
        >
          <li>
            <Link
              to="/deal-stages"
              className={
                "waves-effect left-side-main-menu" +
                (activeSidebar === "deal" ? " active" : "")
              }
              onClick={() => setActiveSidebar("deal")}
              key={activeSidebar}
            >
              <svg
                className="icon_ds_side_bar"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM13.2 17.898V19.2H10.8V17.904C7.9932 17.4636 7.2 15.5016 7.2 14.4H9.6C9.6132 14.5716 9.7908 15.6 12 15.6C13.656 15.6 14.4 14.898 14.4 14.4C14.4 14.0112 14.4 13.2 12 13.2C7.824 13.2 7.2 10.944 7.2 9.6C7.2 8.0544 8.4348 6.4992 10.8 6.102V4.8144H13.2V6.1452C15.2808 6.6372 16.08 8.3688 16.08 9.6H14.88L13.68 9.6216C13.6632 9.1656 13.422 8.4 12 8.4C10.4412 8.4 9.6 9.0192 9.6 9.6C9.6 10.0488 9.6 10.8 12 10.8C16.176 10.8 16.8 13.056 16.8 14.4C16.8 15.9456 15.5652 17.5008 13.2 17.898Z"></path>
              </svg>
              <span className="item__text txt_ds_side_bar">Deals</span>
            </Link>
          </li>
        </BootstrapTooltip>

        <SidebarPopoverMenu
          activeSidebar={activeSidebar}
          sidebarName={"calendar"}
          text={"Calendar"}
          icon={SIDEBAR_ICONS.calendarIcon}
        >
          <div
            id="calender-slide-out"
            className="calender_sidebar__menu_widget calender-sidenav crm_sidebar_menu"
          >
            <div className="calender-sidebar-menu-widget-wrapper crm_sidebar-menu-widget-wrapper">
              <div className="calender_sidebar__menu_content crm_sidebar__menu_content">
                <ul className="calender_sidebar__menu_items crm_sidebar__menu_items m-0">
                  {!isTempUser ? (
                    <li>
                      <Link
                        className="d-inline-block text-decoration-none"
                        to="/activity-calendar"
                        onClick={() => setActiveSidebar("calendar")}
                      >
                        Calendar
                        <span>
                          View and manage your existing activities or create a
                          new one
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <div />
                  )}
                  <li>
                    <Link
                      className="d-inline-block text-decoration-none"
                      to="/calendar-widgets"
                      onClick={() => setActiveSidebar("calendar")}
                    >
                      Calendar Widget
                      <span>
                        View and manage your existing activities or create a new
                        one
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </SidebarPopoverMenu>
        {hiddenSection.search("side-contacts") == -1 && (
          <BootstrapTooltip
            title={props.menuCollapsed ? "Contacts" : ""}
            arrow
            placement="right"
          >
            <li>
              <a
                href="/contacts"
                onClick={(e) =>
                  gotoUrlAndAvoidRerendering(e, "/contacts", "contact")
                }
                className={
                  "waves-effect left-side-main-menu " +
                  (activeSidebar === "contact" ? " active" : "")
                }
              >
                <svg
                  className="icon_ds_side_bar"
                  width="18"
                  height="11"
                  viewBox="0 0 24 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.39437 9.57747C9.22066 9.57747 10.1784 9.69014 11.2676 9.91549C9.35211 10.9671 8.39437 12.3568 8.39437 14.0845V16.7887H0V13.8028C0 12.939 0.507042 12.169 1.52113 11.493C2.57277 10.8169 3.71831 10.3286 4.95775 10.0282C6.19718 9.7277 7.34272 9.57747 8.39437 9.57747ZM13.0704 11.7183C14.5728 11.1174 16.0188 10.8169 17.4085 10.8169C18.7981 10.8169 20.2441 11.1174 21.7465 11.7183C23.2488 12.3193 24 13.108 24 14.0845V16.7887H10.8169V14.0845C10.8169 13.108 11.5681 12.3193 13.0704 11.7183ZM10.9296 6.14085C10.216 6.85446 9.37089 7.21127 8.39437 7.21127C7.41784 7.21127 6.57277 6.85446 5.85916 6.14085C5.14554 5.42723 4.78873 4.58216 4.78873 3.60563C4.78873 2.62911 5.14554 1.78404 5.85916 1.07042C6.57277 0.356808 7.41784 0 8.39437 0C9.37089 0 10.216 0.356808 10.9296 1.07042C11.6432 1.78404 12 2.62911 12 3.60563C12 4.58216 11.6432 5.42723 10.9296 6.14085ZM19.493 7.5493C18.9296 8.11268 18.2347 8.39437 17.4085 8.39437C16.5822 8.39437 15.8685 8.11268 15.2676 7.5493C14.7042 6.94836 14.4225 6.23474 14.4225 5.40845C14.4225 4.58216 14.7042 3.86855 15.2676 3.26761C15.8685 2.66667 16.5822 2.3662 17.4085 2.3662C18.2347 2.3662 18.9296 2.66667 19.493 3.26761C20.0939 3.86855 20.3944 4.58216 20.3944 5.40845C20.3944 6.23474 20.0939 6.94836 19.493 7.5493Z"></path>
                </svg>
                <span className="item__text txt_ds_side_bar">Contacts</span>
              </a>
            </li>
          </BootstrapTooltip>
        )}

        <SidebarPopoverMenu
          activeSidebar={activeSidebar}
          sidebarName={"marketing"}
          text={"Marketing"}
          icon={SIDEBAR_ICONS.marketingIcon}
        >
          <div
            div
            id="marketing-slide-out"
            className="marketing_sidebar__menu_widget marketing-sidenav crm_sidebar_menu"
          >
            <div className="marketing-sidebar-menu-widget-wrapper crm_sidebar-menu-widget-wrapper">
              <div className="marketing_sidebar__menu_content">
                <ul
                  className={`marketing_sidebar__menu_items ${Styles.sidebarMenuScrollBar} m-0`}
                >
                  <li>
                    <Link
                      to="/campaign"
                      className="d-inline-block text-decoration-none"
                      onClick={() => setActiveSidebar("marketing")}
                    >
                      Campaigns
                      <span>
                        View and manage your existing campaigns or create a new
                        one
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/bulk-email"
                      className="d-inline-block text-decoration-none"
                      target="_blank"
                      onClick={() => setActiveSidebar("marketing")}
                    >
                      Bulk Email
                      <Chip
                        size="small"
                        label="New"
                        color="secondary"
                        className="multipage_fb_chip"
                      />
                      <span>
                        Create or customize bulk email campaigns to expand
                        your business.
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/campaign/template"
                      className="d-inline-block text-decoration-none"
                      onClick={() => setActiveSidebar("marketing")}
                    >
                      Campaigns Templates
                      <span>
                        {" "}
                        Use one of our pre-built marketing campaign templates
                      </span>
                    </Link>
                  </li>
                  {Utils.getAccountData("userId") < 8790 && (
                    <>
                      <li>
                        <Link
                          to="/user/form-builder"
                          className="d-inline-block text-decoration-none"
                          onClick={() => setActiveSidebar("marketing")}
                        >
                          Form Builder
                          <span>Create and customize custom form.</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/user/new/form-builder"
                          className="d-inline-block text-decoration-none"
                          onClick={() => setActiveSidebar("marketing")}
                        >
                          Multipage Form Builder
                          <span>Create and customize multipage form.</span>
                        </Link>
                      </li>
                    </>
                  )}

                  <li>
                    <a
                      href="/fbuilder/forms"
                      className="d-inline-block text-decoration-none"
                    >
                      Smart Forms
                      {/*<Chip*/}
                      {/*    size='small'*/}
                      {/*    label='New'*/}
                      {/*    color='secondary'*/}
                      {/*    className='multipage_fb_chip'*/}
                      {/*/>*/}
                      <span>Create and customize multipage form.</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/triggers"
                      className="d-inline-block text-decoration-none"
                    >
                      Triggers
                      <span>Create and customize Triggers</span>
                    </a>
                  </li>
                  {/*{*/}
                  {/*    (Utils.getAccountData("userId") === 1 || Utils.getAccountData("userId") === 8229 ||*/}
                  {/*        Utils.getAccountData("userId") === 12 || Utils.getAccountData("userId") === 15) && (*/}
                  {/*        <li>*/}
                  {/*          <a*/}
                  {/*              href='/wbuilder/sites'*/}
                  {/*              className='d-inline-block text-decoration-none'*/}
                  {/*          >*/}
                  {/*            Websites*/}
                  {/*            <Chip*/}
                  {/*                size='small'*/}
                  {/*                label='New'*/}
                  {/*                color='secondary'*/}
                  {/*                className='multipage_fb_chip'*/}
                  {/*            />*/}
                  {/*            <span>Create and customize awesome websites and grow your business.</span>*/}
                  {/*          </a>*/}
                  {/*        </li>*/}
                  {/*    )*/}
                  {/*}*/}
                  {Utils.getAccountData("agencyId") !== 62 && (
                    <li>
                      <a
                        href="/wbuilder/sites"
                        className="d-inline-block text-decoration-none"
                      >
                        Websites & Funnels
                        <Chip
                          size="small"
                          label="New"
                          color="secondary"
                          className="multipage_fb_chip"
                        />
                        <span>
                          Create and customize awesome websites and grow your
                          business.
                        </span>
                      </a>
                    </li>
                  )}
                  <li>
                    <Link
                      to="/quick-replies"
                      className="d-inline-block text-decoration-none"
                      onClick={() => setActiveSidebar("marketing")}
                    >
                      Quick Replies
                      <span>
                        {" "}
                        Save time by creating templates for commonly sent
                        emails, texts, and voicemails
                      </span>
                    </Link>
                  </li>
                  {/* {(Utils.getAccountData("userId") === 1 ||
                    Utils.getAccountData("userId") === 2 ||
                    Utils.getAccountData("userId") === 446 ||
                    Utils.getAccountData("userId") === 12) && (
                      <li>
                        <Link
                          to='/auto-dialer'
                          className='d-inline-block text-decoration-none'
                          onClick={() => setActiveSidebar("marketing")}
                        >
                          Auto Dialer
                          <span>
                            {" "}
                            Create a prospecting list and quickly call one contact
                            after another
                          </span>
                        </Link>
                      </li>
                    )} */}
                </ul>
              </div>
            </div>
          </div>
        </SidebarPopoverMenu>
        {/* {(Utils.getAccountData('isAgencyMapPackageActive') && Utils.getAccountData('isAgencyMapPackageActive')=== true)  && */}
        {showMapFeatureOption &&
          hiddenSection.search("side-find-leads") === -1 && (
            <SidebarPopoverMenu
              activeSidebar={activeSidebar}
              sidebarName={"map_finder"}
              text={"Find Leads"}
              icon={SIDEBAR_ICONS.findLeadsIcon}
              topNotification={
                Utils.getAccountData("agencyId") === 1 ? "false" : "true"
              }
            >
              <div
                id="mapfinder-slide-out"
                className="marketing_sidebar__menu_widget marketing-sidenav crm_sidebar_menu"
              >
                <div className="marketing-sidebar-menu-widget-wrapper crm_sidebar-menu-widget-wrapper">
                  <div className="marketing_sidebar__menu_content">
                    <ul className="marketing_sidebar__menu_items awesome__scroll_bar m-0">
                      <li>
                        <Link
                          to="/map-finder"
                          className="d-inline-block text-decoration-none"
                          onClick={() => setActiveSidebar("map_finder")}
                        >
                          Find New leads
                          <span>
                            This feature makes it easy to pinpoint new leads in
                            your local market
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/user/map-orders"
                          className="d-inline-block text-decoration-none"
                          onClick={() => setActiveSidebar("map_finder")}
                        >
                          My Lists
                          <span>Your saved lists are stored here</span>
                        </Link>
                      </li>
                      {Utils.getAccountData("agencyId") !== 1 && (
                        <li>
                          <Link
                            to="/map/integration"
                            className="d-inline-block text-decoration-none"
                            onClick={() => setActiveSidebar("map_finder")}
                          >
                            Geofarming Settings
                            <span>you can update GeoFarming settings here</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </SidebarPopoverMenu>
          )}

        {/* Enhance Data */}
        {(Utils.getAccountData("carrierLookup") ||
          Utils.getAccountData("dnc") ||
          Utils.getAccountData("skiptrace")) && (
            <BootstrapTooltip
              title={props.menuCollapsed ? "Enhance Data" : ""}
              arrow
              placement="right"
            >
              <li>
                <Link
                  to="/contacts/clean-list"
                  className={
                    "waves-effect left-side-main-menu" +
                    (activeSidebar === "clean-data" ? " active" : "")
                  }
                  onClick={() => setActiveSidebar("clean-data")}
                >
                  <img
                    className="icon_ds_side_bar"
                    width={`16px`}
                    height={`20px`}
                    src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/enhance-sh.png`}
                    alt="No Data Found"
                  />

                  <span className="item__text txt_ds_side_bar">Enhance Data</span>
                </Link>
              </li>
            </BootstrapTooltip>
          )}

        {/* Power Dialer */}
        <BootstrapTooltip
          title={props.menuCollapsed ? "Power Dialer" : ""}
          arrow
          placement="right"
        >
          <li>
            <Link
              to="/power-dialer"
              target="_blank"
              className={
                "waves-effect left-side-main-menu " +
                (activeSidebar === "power-dialer" ? "active" : "")
              }
              onClick={() => setActiveSidebar("power-dialer")}
            >
              <svg
                className="icon_ds_side_bar"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                fill="none"
              >
                <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
              </svg>
              <span className="item__text txt_ds_side_bar">Power Dialer</span>
            </Link>
          </li>
        </BootstrapTooltip>

        {/* Invoices */}


        {(Utils.getAccountData("agencyBusinessType") === undefined || Utils.getAccountData("agencyBusinessType") !== "INSURANCE") &&

          <BootstrapTooltip
            title={props.menuCollapsed ? "Invoices" : ""}
            arrow
            placement="right"
          >
            <li>
              <a
                href="/ibuilder/invoices"
                className={
                  "waves-effect left-side-main-menu " +
                  (activeSidebar === "invoices" ? " active" : "")
                }
                onClick={() => setActiveSidebar("invoices")}
              >
                <svg
                  className="icon_ds_side_bar"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="none"
                  width="18"
                  height="18"
                >
                  <path d="M28 21a5 5 0 0 0 0 10h3V21zM36 33h-3v10h3a5 5 0 0 0 0-10z" />
                  <path d="M32 1a31 31 0 1 0 31 31A31.036 31.036 0 0 0 32 1zm0 58a27 27 0 1 1 27-27 27.03 27.03 0 0 1-27 27z" />
                  <path d="M32 7a25 25 0 1 0 25 25A25.028 25.028 0 0 0 32 7zm4 38h-3v2a1 1 0 0 1-2 0v-2h-6a1 1 0 0 1 0-2h6V33h-3a7 7 0 0 1 0-14h3v-2a1 1 0 0 1 2 0v2h6a1 1 0 0 1 0 2h-6v10h3a7 7 0 0 1 0 14z" />
                </svg>
                <span className="item__text txt_ds_side_bar">Invoices</span>
              </a>
            </li>
          </BootstrapTooltip>
        }

        {/* Triggers */}
        {/* <BootstrapTooltip
          title={props.menuCollapsed ? "Triggers" : ""}
          arrow
          placement='right'
        >
          <li>
            <Link
              to='/triggers'
              className={
                "waves-effect left-side-main-menu" +
                (activeSidebar === "trigger" ? " active" : "")
              }
              onClick={() => setActiveSidebar("trigger")}
            >
              <svg
                className='icon_ds_side_bar'
                width='18'
                height='18'
                viewBox='0 0 16 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M5.21652 13.3297C5.07916 13.3297 4.97303 13.3297 4.86064 13.3297C3.50582 13.3297 2.15724 13.3297 0.802415 13.3297C0.153098 13.3297 -0.1341 12.9489 0.0594465 12.337C1.27067 8.41614 2.49438 4.50151 3.7181 0.580639C3.85545 0.137355 4.049 0 4.51726 0C6.65875 0 8.80649 0 10.948 0C11.5536 0 11.8346 0.387093 11.6597 0.973975C11.0042 3.17166 10.3424 5.37559 9.68057 7.57328C9.64311 7.69815 9.61189 7.82926 9.56819 7.99783C9.73676 7.99783 9.86787 7.99783 9.99899 7.99783C11.6348 7.99783 13.2643 8.00408 14.9001 7.99159C15.231 7.99159 15.4994 8.06651 15.6493 8.37868C15.7991 8.6971 15.6805 8.95308 15.4745 9.20282C11.5536 13.9915 7.63273 18.7865 3.71185 23.5752C3.59947 23.7063 3.4746 23.8437 3.32476 23.9248C3.08127 24.0622 2.83777 23.9997 2.6255 23.8312C2.39449 23.6501 2.3383 23.4004 2.41322 23.1257C2.53809 22.6512 2.68169 22.1891 2.81904 21.7146C3.59323 19.0175 4.37366 16.3203 5.14784 13.6294C5.17282 13.5482 5.1853 13.4608 5.21652 13.3297Z'></path>
              </svg>
              <span className='item__text txt_ds_side_bar'>Triggers</span>
            </Link>
          </li>
        </BootstrapTooltip> */}

        {/* Reporting */}
        {hiddenSection.search("side-reporting") == -1 && (
          <SidebarPopoverMenu
            activeSidebar={activeSidebar}
            sidebarName={"report"}
            text={"Reporting"}
            icon={SIDEBAR_ICONS.reportIcon}
          >
            <div
              id="reporting-slide-out"
              className="marketing_sidebar__menu_widget marketing-sidenav crm_sidebar_menu"
            >
              <div className="marketing-sidebar-menu-widget-wrapper crm_sidebar-menu-widget-wrapper">
                <div className="marketing_sidebar__menu_content">
                  <ul className="marketing_sidebar__menu_items awesome__scroll_bar m-0">
                    <li>
                      <a
                        href="/user/products"
                        onClick={goToDealSalesReportLink}
                      >
                        Sales Reports
                        <span>
                          Run detailed sales reports to track your business
                          performance
                        </span>
                      </a>
                    </li>

                    {/* <li>
                        <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.DEAL_REPORT)}>
                        <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                            {SettingIconList.companyInfo}
                          </div>
                          <h6>Deal report</h6>
                            </a>
                      </li> */}

                    <li>
                      <Link
                        to="/business-report"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Business report
                        <span>Your business analytics dashboard</span>
                      </Link>
                    </li>
                    {(Utils.getAccountData("agencyBusinessType") === undefined || Utils.getAccountData("agencyBusinessType") !== "INSURANCE") &&
                      (
                        <li>
                          <Link
                            to="/goal/progress"
                            className="d-inline-block text-decoration-none"
                            onClick={() => setActiveSidebar("report")}
                          >
                            Goal Report
                            <span>Create company and individual goals</span>
                          </Link>
                        </li>
                      )}
                    {/* {Utils.getAccountData('agencyId') === 1 &&  */}
                    <li>
                      <Link
                        to="/user-order/report"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Direct Mail Orders
                        <span>Your direct mail history</span>
                      </Link>
                    </li>
                    {/* } */}
                    <li>
                      <Link
                        to="/batches"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Drip Batch Report
                        <span> Assign contacts to campaign in drip mode.</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/call-logs"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Call Logs
                        <span> Your call log history</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/power-dialer/call-logs"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Power Dialer Call Logs
                        <span> Your power dialer call log history</span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/user/smart-webhook-report"
                        className="d-inline-block text-decoration-none"
                        onClick={() => setActiveSidebar("report")}
                      >
                        Smart Webhook Report
                        <span> Your smart webhook report</span>
                      </Link>
                    </li>

                    {showTwilioSurChargeReport && (
                      <>
                        <li>
                          <Link
                            to="/bills"
                            className="d-inline-block text-decoration-none"
                            onClick={() => setActiveSidebar("report")}
                          >
                            Bill Report
                            <span> Carrier Bill List</span>
                          </Link>
                        </li>

                        {/*{Utils.getAccountData('userIsOwner') &&*/}
                        {/*<li>*/}
                        {/*    <Link to="/transactions" className="d-inline-block text-decoration-none" onClick={() => setActiveSidebar('report')}>*/}
                        {/*        Transaction Report*/}
                        {/*        <span>Carrier transaction list</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*}*/}
                      </>
                    )}
                    {Utils.getAccountData("carrierLookup") && (
                      <li>
                        <Link
                          to="/user/dnc-numbers"
                          className="d-inline-block text-decoration-none"
                          onClick={() => setActiveSidebar("report")}
                        >
                          Do-Not-Call (DNC)
                          <span>Review your internal DNC list</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </SidebarPopoverMenu>
        )}

        <BootstrapTooltip
          title={props.menuCollapsed ? "Settings" : ""}
          arrow
          placement="right"
        >
          <li>
            <a
              href="/user/settings"
              onClick={(e) =>
                gotoUrlAndAvoidRerendering(e, "/user/settings", "setting")
              }
              className={
                "waves-effect left-side-main-menu" +
                (activeSidebar === "setting" ? " active" : "")
              }
            >
              <svg
                className="icon_ds_side_bar"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.73709 14.9859C9.56338 15.8122 10.5587 16.2254 11.723 16.2254C12.8873 16.2254 13.8826 15.8122 14.7089 14.9859C15.5352 14.1596 15.9484 13.1643 15.9484 12C15.9484 10.8357 15.5352 9.84038 14.7089 9.01408C13.8826 8.18779 12.8873 7.77465 11.723 7.77465C10.5587 7.77465 9.56338 8.18779 8.73709 9.01408C7.9108 9.84038 7.49765 10.8357 7.49765 12C7.49765 13.1643 7.9108 14.1596 8.73709 14.9859ZM20.6808 13.1831L23.216 15.1549C23.4789 15.3427 23.5164 15.6056 23.3286 15.9437L20.9061 20.1127C20.7559 20.3756 20.5117 20.4507 20.1737 20.338L17.1878 19.1549C16.3991 19.7183 15.723 20.1127 15.1596 20.338L14.7089 23.493C14.6338 23.831 14.446 24 14.1455 24H9.30047C9 24 8.81221 23.831 8.73709 23.493L8.28638 20.338C7.57277 20.0376 6.89671 19.6432 6.25822 19.1549L3.2723 20.338C2.93427 20.4507 2.69014 20.3756 2.53991 20.1127L0.117371 15.9437C-0.0704225 15.6056 -0.0328638 15.3427 0.230047 15.1549L2.76526 13.1831C2.7277 12.9202 2.70892 12.5258 2.70892 12C2.70892 11.4742 2.7277 11.0798 2.76526 10.8169L0.230047 8.84507C-0.0328638 8.65728 -0.0704225 8.39437 0.117371 8.05634L2.53991 3.88732C2.69014 3.62441 2.93427 3.5493 3.2723 3.66197L6.25822 4.84507C7.04695 4.28169 7.723 3.88732 8.28638 3.66197L8.73709 0.507042C8.81221 0.169014 9 0 9.30047 0H14.1455C14.446 0 14.6338 0.169014 14.7089 0.507042L15.1596 3.66197C15.8732 3.96244 16.5493 4.35681 17.1878 4.84507L20.1737 3.66197C20.5117 3.5493 20.7559 3.62441 20.9061 3.88732L23.3286 8.05634C23.5164 8.39437 23.4789 8.65728 23.216 8.84507L20.6808 10.8169C20.7183 11.0798 20.7371 11.4742 20.7371 12C20.7371 12.5258 20.7183 12.9202 20.6808 13.1831Z"></path>
              </svg>
              <span className="item__text txt_ds_side_bar">Settings</span>
            </a>
          </li>
        </BootstrapTooltip>

        <SidebarNewCustomMenu menuCollapsed={props.menuCollapsed} />
      </ul>
    </aside>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeInitialStates: (params) => dispatch(storeInitialStates(params)),
  };
};

export default React.memo(connect(null, mapDispatchToProps)(Sidebar));

const SIDEBAR_ICONS = {
  calendarIcon: (
    <svg
      className='icon_ds_side_bar'
      width='18'
      height='18'
      viewBox='0 0 22 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M13.2084 15.6253V17.9859H4.77752V15.6253H13.2084ZM19.1663 21.5831V8.43091H2.41686V21.5831H19.1663ZM19.1663 2.41686C19.8033 2.41686 20.3653 2.66042 20.8525 3.14754C21.3396 3.63466 21.5831 4.19672 21.5831 4.83372V21.5831C21.5831 22.2201 21.3396 22.7822 20.8525 23.2693C20.3653 23.7564 19.8033 24 19.1663 24H2.41686C1.74239 24 1.16159 23.7564 0.674473 23.2693C0.224824 22.7822 0 22.2201 0 21.5831V4.83372C0 4.19672 0.224824 3.63466 0.674473 3.14754C1.16159 2.66042 1.74239 2.41686 2.41686 2.41686H3.59719V0H6.01405V2.41686H15.5691V0H17.9859V2.41686H19.1663ZM16.8056 10.7916V13.2084H4.77752V10.7916H16.8056Z'></path>
    </svg>
  ),
  marketingIcon: (
    <svg
      className='icon_ds_side_bar'
      width='18'
      height='16'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z'></path>
    </svg>
  ),
  findLeadsIcon: (
    <svg
      className='icon_ds_side_bar'
      width='18'
      height='17'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M8.36599 3.85938C6.59193 3.85938 5.14847 5.24511 5.14847 6.9463C5.13681 9.43475 8.24373 11.4981 8.36599 11.5815C8.36599 11.5815 11.5952 9.43475 11.5835 6.94823C11.5835 5.24511 10.1401 3.85938 8.36599 3.85938ZM8.36599 8.49265C7.47715 8.49265 6.75723 7.80152 6.75723 6.94823C6.75723 6.09493 7.47715 5.4038 8.36599 5.4038C9.25483 5.4038 9.97475 6.09493 9.97475 6.94823C9.97475 7.80152 9.25483 8.49265 8.36599 8.49265Z'></path>
      <path d='M16.0878 14.1572H15.071L14.7107 13.8097C15.9719 12.3425 16.7313 10.4377 16.7313 8.36564C16.7313 3.74523 12.986 0 8.36564 0C3.74523 0 0 3.74523 0 8.36564C0 12.986 3.74523 16.7313 8.36564 16.7313C10.4377 16.7313 12.3425 15.9719 13.8097 14.7107L14.1572 15.071V16.0878L20.5923 22.51L22.51 20.5923L16.0878 14.1572ZM8.36564 14.1572C5.16095 14.1572 2.57404 11.5703 2.57404 8.36564C2.57404 5.16095 5.16095 2.57404 8.36564 2.57404C11.5703 2.57404 14.1572 5.16095 14.1572 8.36564C14.1572 11.5703 11.5703 14.1572 8.36564 14.1572Z'></path>
    </svg>
  ),
  reportIcon: (
    <svg
      className='icon_ds_side_bar'
      width='18'
      height='16'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M16.7593 18.8272C17.0615 19.1177 17.0386 19.611 16.69 19.8485C14.9449 21.0414 12.8623 21.6933 10.7211 21.7167C5.06633 21.7842 0.198633 17.3275 0.0062725 11.8928C-0.180794 6.60097 3.83849 2.18541 9.09576 1.47119C9.51269 1.41436 9.88285 1.74729 9.88285 2.15233V12.2164L16.7593 18.8272Z'></path>
      <path d='M22.5887 9.4678C22.2234 4.40122 18.0117 0.352526 12.7412 0.0013507C12.3388 -0.0236726 12 0.302479 12 0.690128V10.1803H21.8726C22.2759 10.1803 22.6147 9.8546 22.5887 9.4678ZM23.2853 12.2161H12.8162L19.7871 18.9173C20.0518 19.1718 20.493 19.1942 20.7661 18.9461C22.5167 17.3651 23.6547 15.258 23.9926 12.9719C24.053 12.572 23.7067 12.2161 23.2853 12.2161Z'></path>
    </svg>
  ),
};
