import { Button, withStyles } from "@material-ui/core";
import styled from "styled-components";
export const InsuranceWrapper = styled("div")({
    padding: ".75rem",
    display: "flex",
    flexDirection: "column",
    height: "100vh"
});

export const InsuranceCardWrapper = styled("div")({
    width:'100%',
    height: "150px",
    borderRadius: 8,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
});
export const InsuranceCardContainer = styled("div")({
    width:'100%',
    height: `calc(100vh - 50px)`,
    overflowY:'auto',
    overflowX:'hidden'
    
})

export const InsuranceCard = styled("div")({
    width:'100%',
    display:'flex',
    marginBottom:'24px'
});

export const InsuranceHeader=styled('div')({
    background:'#316AFF',
    height:'auto',
    borderRadius:'8px',
    color:'white',
    width:'100%',
    padding:'3px 16px 6px 16px',
   
})
export const InsuraceTitle = styled("p")({
    color: "#fff !important",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight:0,
});
export const InsuraceStatusForActive = styled("p")({
    color: "var(--dark_blue) !important",
    fontSize: "12px",
    background:'rgb(0, 255, 145)',
    borderRadius:'10px',
    padding:'0px 10px',
    fontWeight:400
 
});

export const InsuraceStatusForInActive = styled("p")({
    color: "white !important",
    fontSize: "12px",
    background:'red',
    borderRadius:'10px',
    padding:'0px 10px',
    fontWeight:400
 
});
export const InsuraceFlexBox=styled('div')({
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    width:'100%',
    height:'28px',
    fontSize:'14px'
})

export const InsuraceCardBody=styled('div')({
  padding:'10px 7px 10px 16px',
  width:'100%'
})

export const InsuraceCardFooter=styled('div')({
    padding:'3px 16px',
    width:'100%',
    position:'absolute',
    bottom:0,
    background:'#F2F3F5',
    borderBottomLeftRadius:'8px',
    borderBottomRightRadius:'8px'
})

export const NewInsurancePolicy=styled('div')({
  display:'flex',
  justifyContent:'end',
  marginBottom:16
})
export const InsurancePolicyEditPopOver=styled('div')({
  minWidth:'250px',
  
})
export const InsurancePolicyEditPopOverFlexBox=styled('div')({
    display:'flex',
    gap:1,
    paddingLeft:'8px',
    alignItems:'center',
    "&:hover":{
        background:'#EBF2FE'
       } ,
    
  })
export const InsurancePopOverItemText=styled('p')({
   width:'100%',
   color:'black',
   padding:'12px 12px',
   margin:0, 
   cursor:'pointer'
})

export const InsuranceIconContainer=styled("div")({
    // width:'30px',
    height:'30px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'50%',
    "&:hover":{
       background:'#F2F3F5',
       transition:'all .5s'
    },
    cursor:'pointer'
})
export const BasicButton = withStyles((theme) => ({
    root: {
        fontWeight: "600 !important",
        backgroundColor: "#316AFF !important",
        color: "white !important",
        textTransform: "capitalize !important",
        padding: "7px 12px 8px  !important",
        borderRadius: "5px !important",
        "&:hover, &:focus": {
            backgroundColor: "#316AFF !important",
            color: "white !important",
        },
        "&:disabled": {
            backgroundColor: "#b3ceff !important"
        }
    }
}))(Button);
  

