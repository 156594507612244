import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  ContentWrapper,
  SearchAndAddWrapper,
} from "../GlobalAddDealModalStyles"

const useStyles = makeStyles({
  titleText: {
    color: "#181f48",
    fontWeight: "600 !important",
    marginBottom: "0px !important",
  },
});

const GlobalAddDealModalSkeleton = () => {
  const { titleText } = useStyles();

  return (
    <ContentWrapper>
        
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SearchAndAddWrapper>
            <Skeleton variant="rectangular" width={650} height={40} />
            <Skeleton variant="rectangular" width={170} height={40} style={{ marginLeft: '1rem' }} />
          </SearchAndAddWrapper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Skeleton variant="text" width="20%" className={titleText} />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Grid>

        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="40%" className={titleText} />
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "25px" }}>
          <Skeleton variant="text" width="30%" className={titleText} />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={200} height={40} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default GlobalAddDealModalSkeleton;
