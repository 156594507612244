import React, { useContext, useState, useEffect } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import SecurityIcon from '@material-ui/icons/Security';
import AddIcon from "@material-ui/icons/Add";
import { icons } from "../../icons";
import ApiList from "../../ApiList";
import EachInsurance from "./EachInsurance";


const Policy = (props) => {
  const [expand, setExpand] = useState(false);
  const [activity, setActivity] = useState([]);
 
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setActivity(props.activity);
  }, [props.activity]);



  



  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
        >
          <span className={`${expand ? "g-header-title-for-deal" : "g-header-title"}`}>
            {" "}
           <SecurityIcon/>
           Policy <span className="g-header-count" style={{color:'black'}}>{count}</span>
          </span>
          <div className="g-header-action-content">
            <span className={`${expand?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <div className="g-contact-appointment-content">
                <div
                  className="g-contact-appointment-list awesome__scroll_bar"
                  style={{ paddingBottom: "8px",marginTop:'8px' }}
                >
                <EachInsurance/>
                </div>

              
          </div>
        </CustomAccordionBody>
      </CustomAccordion>
    </div>
  );
};
export default Policy;
