import {useContext} from 'react';
import CustomSelect from '../components/CustomSelect';
import { SmsSendContext } from "../reducers/GlobalReduers"
import { Box, MenuItem } from "@material-ui/core";
import { updateSelectedVn } from '../reducers/Action';
import BasicSelect from '../../../custom/BasicSelect'

const FromNumber = (props) => {
    const {states, dispatch} = useContext(SmsSendContext)

    const hanldeVirtualNumber = (value) => {
        dispatch(updateSelectedVn(value))
    }

    return(
        <div className="sms__email__wrapper__alt">
            <div className="sms__email__wrapper__top">
                <h3 className="sms__email__wrapper__top__title">From Number: </h3>
                <div className="sms__email__wrapper__top__select">
               
                   <BasicSelect
                        fullWidth
                        name="virtual_number"
                        options={states.vnList}
                        defaultText="Select a virtual number "
                        value={states.selctedVn}
                        onChange={(event) => hanldeVirtualNumber(event.target.value)}
                        mapping={{
                          label: "label",
                          value: "value"
                      }}
                      />
         
                    {/* <CustomSelect
                    formControlClass="sms__email__wrapper__top__select__innter"
                    value={states.selctedVn}
                    handleChange={(event) => hanldeVirtualNumber(event.target.value)}
                    placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a virtual number </MenuItem>)}
                    item={states.vnList}
                    titel_field={'label'}
                    value_field={'value'}
                    menuItemClass="dropdownhelper-menuitem-class"                
                /> */}
                </div>
            </div>
            
        </div>
    )
}
export default FromNumber;