import { InputBase, makeStyles, withStyles } from "@material-ui/core";

export const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      fontFamily: 'Poppins !important',
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      position: "absolute",
      right: "12px",
    },
  },

  input: {
    position: "relative",
    backgroundColor: "white !important",
    fontSize: "15px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    fontWeight: '500',
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },

    "&::placeholder": {
      color: "#082852 !important",
      fontWeight: "500 !important",
      fontSize: "15px " 
    },
  },
}))(InputBase);


export const BasicInputSelect = withStyles((theme) => ({
  root: {
      "label + &": {
          marginTop: theme.spacing(3),
      },
  },
  input: {
      backgroundColor: "white !important",
      position: "relative",
      fontSize: "15px !important",
      margin: "0 !important",
      padding: " 9px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
          borderRadius: 4,
          boxShadow: "unset !important",
          backgroundColor: "#fff !important"
      },
      "&::placeholder": {
          color: "#082852 !important",
      },

  },
}))(InputBase);

export const AddContactModalV2Styles = makeStyles({
  headlineWrapper: {
    color: "white",
    backgroundColor: "#3C7EF3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    padding: "10px 16px",
    borderRadius: "5px",

    "& .MuiDivider-root": {
      color: "white",
      backgroundColor: "white",
    },
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
    "&.Mui-selected": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
  },
  focusBackground: {
    "&.MuiIconButton-root:focus": {
      backgroundColor: "unset",
    },
  },
  labelMarginBottom: { marginBottom: 4 },

  addressHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  manualEntryButton: {
    backgroundColor: "#3C7EF3",
    fontWeight: "600px",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
    marginBottom: "5px",
    "&:hover": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
  },
});
