import React, {Fragment, useEffect, useState} from "react";
import {
    Backdrop, Button,
    Fade,
    makeStyles,
    Modal,
    Typography
} from "@material-ui/core";
import {CloseIcon} from "./Icons";
import EachRelatedItem from "./EachRelatedItem";
import {getContactRelationshipList} from "../../api/contactApi";
import Loading from "./Loading";
import Utils, {formatPhoneNumber} from "../../helpers/Utils";

const modalStyles = makeStyles((theme) => ({
    wrapperMain: {
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "6px",
        marginBottom: "20px",
        "& .openButton": {
            backgroundColor: "#3C7EF3 !important",
            fontWeight: "600 !important",
            color: "white !important",
            minWidth: "fit-content !important",
            "&:hover, &:focus": {
                backgroundColor: "#3C7EF3 !important",
                color: "white !important",
            },
        }
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 390,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
    },
    modalHeader: {
        padding: "16px",
        background: "#346fef",
        color: "#fff",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .closeIcon": {
            display: "flex",
            cursor: "pointer",
            "& svg": {
                color: "#fff",
                backgroundColor: "var(--red)",
                width: "25px",
                height: "25px",
                borderRadius: "50px"
            },
        },

        "& .title": {
            height: "unset !important",
            color: "white",
            fontWeight: 600,
            fontSize: 16
        }
    },
    modalBody: {
        padding: "16px",
        "& .list": {
            display: "flex",
            flexDirection: "column",
            gap: 12,
            "& .item": {
                padding: 8,
                borderRadius: 6,
                "&:hover": {
                    backgroundColor: "rgba(60,126,243,.1)",
                },
                "& .linkUnderline": {
                    color: "var(--light_blue)",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: 1
                },
                "& .relationship": {
                    color: "#181F48",
                    height: "fit-content !important",
                },
                "& .itemContent": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                },
                "& .itemText": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                },
                "& .iconButton": {
                    color: "lightGray !important",
                    "& .MuiButtonBase-root": {
                        padding: "0 !important"
                    }
                },
                "& .actionListHover": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 8,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    marginTop: 8,
                    paddingTop: 16,
                    paddingBottom: 8,
                    "& .actionItem": {
                        backgroundColor: "#316aff",
                        "& svg": {
                            fill: "white",
                            color: "white",
                            height: 30,
                            width: 30,
                            "& path": {
                                fill: "white",
                            },
                        },
                    },
                },
            },
        },
        "& .save": {
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "15px",
            "& .saveButton": {
                backgroundColor: "#3C7EF3 !important",
                fontWeight: "600 !important",
                color: "white !important",
                minWidth: "fit-content !important",
                "&:hover, &:focus": {
                    backgroundColor: "#3C7EF3 !important",
                    color: "white !important",
                },
                width: "100%"
            }
        }
    },
    modalBodyInput: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    },
    modalFooter: {
        background: "rgb(24, 31, 72)",
        borderEndEndRadius: "8px",
        borderEndStartRadius: "8px",
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .title": {
            color: "#fff",
            fontWeight: "600",
        },

        "& .btnSubmit": {
            background: "#346fef !important",
            fontWeight: "600"
        },
    },
    errorText: {
        color: "red",
        marginTop: "4px",
        fontSize: "0.875rem",
    },
    contactTabs: {
        display: "flex",
        gap: "5px",
        "& .contactTab": {
            backgroundColor: "#6c6ce4",
            width: "fit-content",
            borderRadius: "12px",
            padding: "0px 10px",
            color: "white",
            marginTop: "10px",
        },
    }
}));

const generateName = (contact) => {
    let name = "";

    if (contact.firstName) {
        name += contact.firstName;
    }

    if (contact.lastName) {
        if (name) {
            name += " ";
        }
        name += contact.lastName;
    }

    if (!name && contact.email){
        name = contact.email;
    }

    if (!name && contact.number){
        name = formatPhoneNumber(contact.number);
    }

    return name;
};

const GlobalContactRelatedContactSelector = ({ data = null }) => {
    const classes = modalStyles();
    const [relatedContacts, setRelatedContacts] = useState([]);
    const [selectedRelatedContactIds, setSelectedRelatedContactIds] = useState([]);
    const [openContactRelatedContactSelector, setOpenContactRelatedContactSelector] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);

    useEffect(() => {
        if (Utils.getAccountData("userId") === 1 || Utils.getAccountData("userId") === 446){
            if (data && data.contactId && !alreadyLoaded) {
                (async () => {
                    try {
                        const response = await getContactRelationshipList({contactId: data.contactId});
                        if (response && response.success && response.data[0]) {
                            let contacts = response.data;
                            if (data.type === "email") {
                                contacts = response.data.filter(contact => contact.email !== "" && contact.email !== null).map((contact)=>({ ...contact, number: null }));
                            } else if (data.type === "number") {
                                contacts = response.data.filter(contact => contact.number !== "" && contact.number !== null).map((contact)=>({ ...contact, email: null }));
                            }

                            setRelatedContacts(contacts);
                        } else {
                            setRelatedContacts([]);
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsLoading(false);
                        setAlreadyLoaded(true);
                    }
                })();
            }
        }
    }, [data, alreadyLoaded]);

    const handleCheckbox = (id) => {
        setSelectedRelatedContactIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((item) => item !== id);
            } else {
                return [id, ...prevSelectedIds];
            }
        });
    };

    const checked = (id) => selectedRelatedContactIds.includes(id);

    const handleSave = () => {
        if (data && data.callback) {
            data.callback({contactIds: selectedRelatedContactIds});
        }
        handleClose();
    };

    const handleClose = () => {
        setOpenContactRelatedContactSelector(false);
    };

    const handleOpen = () => {
        setOpenContactRelatedContactSelector(true);
    };

    const renderContactInfo = (id) => {
        const contact = relatedContacts.find((relatedContact) => (relatedContact.relatedContactId === id));

        if (contact) {
            return (
                <div className="contactTab">
                    {generateName(contact)}
                </div>
            );
        }

        return null;
    };

    return (
        relatedContacts[0] ?
            <div className={classes.wrapperMain}>
                <Button className="openButton" onClick={handleOpen}>
                    Send to Related Contacts
                </Button>

                <div className={classes.contactTabs}>
                    {
                        data &&
                        data.contactIds &&
                        data.contactIds.map(renderContactInfo)
                    }
                </div>
                <Modal
                    open={openContactRelatedContactSelector}
                    onClose={handleClose}
                    className={classes.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openContactRelatedContactSelector}>
                        <div className={classes.paper}>
                            <div className={classes.modalHeader}>
                                <Typography className="title" variant="h5">Select the related contacts you'd like to
                                    send your message to:</Typography>

                                <div className="closeIcon" onClick={handleClose}>
                                    <CloseIcon/>
                                </div>
                            </div>

                            <div className={classes.modalBody}>
                                {
                                    isLoading ?
                                        <Loading/> :
                                        <Fragment>
                                            <div className="list">
                                                {
                                                    relatedContacts.map((relatedContact, index) => (
                                                        <EachRelatedItem
                                                            index={index}
                                                            relatedContact={relatedContact}
                                                            checked={checked}
                                                            handleCheckbox={handleCheckbox}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            <div className="save">
                                                <Button className="saveButton" onClick={handleSave}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Fragment>
                                }
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div> :
            null
    );
};

export default GlobalContactRelatedContactSelector;