import { List, ListItem, ListItemText, makeStyles, Popover, Typography } from '@material-ui/core';
import React from 'react';
import { InsurancePolicyEditPopOver, InsurancePolicyEditPopOverFlexBox, InsurancePopOverItemText } from './PolicyStyle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
const EditPolicyPopOver = ({id,open,anchorEl,handleClose}) => {
    return (
        <div>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
       <InsurancePolicyEditPopOver>
        <InsurancePolicyEditPopOverFlexBox>
          <EditIcon/>
        <InsurancePopOverItemText>
        Edit policy info
     </InsurancePopOverItemText>
        </InsurancePolicyEditPopOverFlexBox>
        <InsurancePolicyEditPopOverFlexBox>
          <AirplanemodeInactiveIcon/>
        <InsurancePopOverItemText>
        Make policy inactive
     </InsurancePopOverItemText>
        </InsurancePolicyEditPopOverFlexBox>
        <InsurancePolicyEditPopOverFlexBox>
          <DeleteIcon color='error'/>
        <InsurancePopOverItemText>
        Delete policy
     </InsurancePopOverItemText>
        </InsurancePolicyEditPopOverFlexBox>
       </InsurancePolicyEditPopOver>
      </Popover>
        </div>
    );
};

export default EditPolicyPopOver;