import React, { Fragment, useEffect, useState } from "react";
import {
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    withStyles
} from "@material-ui/core";
import {
    ContentWrapper,
    BasicButton,
    SearchAndAddWrapper,
    BasicFormLabel,
    BasicAddButton,
    BasicFormHelperText
} from "../GlobalAddDealModalV3/GlobalAddDealModalStyles";
import AddIcon from "@material-ui/icons/Add";
import ContactSearch from "../GlobalAddDealModalV3/components/ContactSearch";
import BasicSelect from "../GlobalAddDealModalV3/components/BasicSelect";
import {
    getContactStageProducts,
    getUserPypelines,
    getUserPypelineStages
} from "../GlobalAddDealModalV3/helpers/Api";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CustomTitleAddDeal from "../GlobalAddDealModalV3/components/CustomTitleAddDeal";
import moment from "moment";
import GlobalAddDealModalSkeleton from "../GlobalAddDealModalV3/components/GlobalAddDealModalSkeleton";

const BasicInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
        fontFamily: 'Poppins !important',
      },
      "&.MuiInputBase-multiline": {
        padding: "0 !important",
      },
      "& .MuiInputAdornment-positionEnd": {
        position: "absolute",
        right: "12px",
      },
    },
  
    input: {
      position: "relative",
      backgroundColor: "white !important",
      fontSize: "15px !important",
      margin: "0 !important",
      height: "2.5rem !important",
      padding: "10px 12px 10px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      fontWeight: '500',
      transition: theme.transitions.create(["border-color", "box-shadow"]),
  
      "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
      },
  
      "&::placeholder": {
        color: "#082852 !important",
        fontWeight: "500 !important",
        fontSize: "15px " // Match this with the input font size
      },
    },
  }))(InputBase);
  


const useStyles=makeStyles({
    titleText:{
        color:' #181f48',
        fontWeight: "600 !important",
        marginBottom: "0px !important"
    },
    productValueSign:{
        position: "absolute",
        top: "8px",
        left: "10px",
        zIndex: 1
    }
})

const status={
    ACTIVE:'ACTIVE',
    TRASH:'TRASH'
}

const GlobalAddDealModalV3ForPolicy = ({ isEdit, showContactSearch, formData, errors, handleChangeFormData, selectedContact,setSelectedContact, setLabels, userProducts }) => {
    const [isLoadingResource, setIsLoadingResource] = useState(false);
    const [pipelines, setPipelines] = useState([]);
    const [dealStages, setDealStages] = useState([]);
    const placeholderColor =  "rgb(8 40 82 / 42%)";
    const activeColor = "#36454F";
    const todayDate = moment().format('YYYY-MM-DD');
    const {titleText,productValueSign}=useStyles();

    useEffect(() => {
        (async () => {
            try {
                setIsLoadingResource(true);
                const resp = await getUserPypelines();
                if (resp?.data) {
                    setPipelines(resp.data);
                } else {
                    setPipelines([]);
                }

                if (isEdit) {
                    if (formData.contact_stages_id !== undefined && formData.contact_stages_id !== null && formData.contact_stages_id !== "") {
                        const resp2 = await getContactStageProducts({ contact_stages_id: formData.contact_stages_id });

                        if (resp2.success && resp2.data[0]) {
                            
                            handleChangeFormData("user_contact_stage_products", resp2.data);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoadingResource(false);
            }
        })();
    }, [isEdit, formData.contact_stages_id]);
    
    useEffect(() => {
        if (formData.user_pipeline_id) {
            (async () => {
                const resp = await getUserPypelineStages({ pipelineId: formData.user_pipeline_id });
                if (resp?.dealStage) {
                    setDealStages(resp?.dealStage);
                }
            })();
        }
    }, [formData.user_pipeline_id]);

    const handleSelectPipeline = async (e) => {
        try {
            handleChangeFormData("user_stage_id", "");
            handleChangeFormData("user_pipeline_id", e.target.value);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSelectStage = (e) => {
        handleChangeFormData("user_stage_id", e.target.value);

        try{
            let pipelineTitle = "";
            let stageTitle = "";

            let findPipeline = pipelines.find((pipeline)=>(pipeline.id === formData.user_pipeline_id));

            if (findPipeline){
                pipelineTitle = findPipeline.title;
            }

            let findStage = dealStages.find((stage)=>(stage.value === parseInt(e.target.value)));

            if (findStage){
                stageTitle = findStage.label;
            }

            setLabels({
                pipelineTitle: pipelineTitle,
                stageTitle: stageTitle
            });
        }catch (err){
            console.log(err);
        }
    };

    const handleProductChange = (index, key, value) => {
        let old = [...formData.user_contact_stage_products];

        if (key === "value" && typeof value === "string" && value.trim() !== "") {
            if (value.length > 10) {
                return;
            }

            value = parseFloat(value)

            const selectedValue = parseInt(value);
            if (isNaN(selectedValue)) {
                return;
            }
        }

        old[index][key] = value;

        let total = 0;

        old.forEach((item) => {
            if (item.value !== "") {
                total += parseFloat(item.value);
            }
        });

        handleChangeFormData("deal_value", total);
        handleChangeFormData("user_contact_stage_products", old, index);
    };

    const handleAddProduct = () => {
        let old = [...formData.user_contact_stage_products];

        old.push({
            user_product_id: "",
            value: 0,
            status:status.ACTIVE
        });

        handleChangeFormData("user_contact_stage_products", old);
    };

    const handleDeleteProduct = (index) => {
        let old = [...formData.user_contact_stage_products];

        old.splice(index, 1);

        let total = 0;

        old.forEach((item) => {
            if (item.value !== "") {
                total += parseFloat(item.value);
            }
        });

        handleChangeFormData("deal_value", total);
        handleChangeFormData("user_contact_stage_products", old);
    };

    const callbackSelectContact = (contact) => {
        if (contact) {
            handleChangeFormData("contact_id", contact.id);
            setSelectedContact(contact);
        }
    };

    const callbackCreateContact = (contact) => {
        if (contact) {
            handleChangeFormData("contact_id", contact.id);
            let fullName = "";
            let email = "";
            let number = "";

            if (contact.first_name) {
                fullName = contact.first_name;
            }

            if (contact.last_name) {
                if (fullName) {
                    fullName += " ";
                }
                fullName += contact.last_name;
            }

            if (contact.emails[0]?.email) {
                email = contact.emails[0].email;
            }

            if (contact.numbers[0]?.number) {
                number = contact.numbers[0].number;
            }

            setSelectedContact({ fullName, email, number });
        }
    };

    return (
        <ContentWrapper>
            {
                isLoadingResource ?
                    <GlobalAddDealModalSkeleton/> :
                    <Grid container spacing={2}>
                        {
                            showContactSearch && !isEdit &&
                            <Grid item xs={12} md={12}>
                                <SearchAndAddWrapper>
                                    <ContactSearch
                                        callback={callbackSelectContact}
                                    />
                                    <BasicButton
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            window.globalAddContactHelper(true, {
                                                callback: (data) => {
                                                    callbackCreateContact(data);
                                                }
                                            });
                                        }}
                                        disableElevation
                                    >
                                        New Contact
                                    </BasicButton>
                                </SearchAndAddWrapper>
                                {
                                    errors.contact_id &&
                                    <BasicFormHelperText>
                                        {errors.contact_id}
                                    </BasicFormHelperText>
                                }
                            </Grid>

                        }

                        {
                            selectedContact?.fullName &&
                            <Grid item xs={12} md={12}>
                                <BasicFormLabel variant="body1">
                                    Name
                                </BasicFormLabel>
                                <BasicInput
                                    name="name"
                                    fullWidth
                                    value={selectedContact.fullName}
                                    onChange={(e) => {

                                    }}
                                    readOnly
                                />
                            </Grid>
                        }

                        {
                            selectedContact?.email &&
                            <Grid item xs={12} md={12}>
                                <BasicFormLabel variant="body1">
                                    Email
                                </BasicFormLabel>
                                <BasicInput
                                    name="email"
                                    fullWidth
                                    value={selectedContact.email}
                                    onChange={(e) => {

                                    }}
                                    readOnly
                                />
                            </Grid>
                        }

                        {
                            selectedContact?.number &&
                            <Grid item xs={12} md={12}>
                                <BasicFormLabel variant="body1">
                                    Number
                                </BasicFormLabel>
                                <BasicInput
                                    name="number"
                                    fullWidth
                                    value={selectedContact.number}
                                    onChange={(e) => {

                                    }}
                                    readOnly
                                />
                            </Grid>
                        }

                        <Grid item xs={12} md={12}>
                            <BasicFormLabel className={titleText}>
                                Deal Name
                            </BasicFormLabel>
                            <BasicInput
                                placeholder="Enter deal name"
                                value={formData.title}
                                fullWidth
                                style={{color:activeColor}}
                                onChange={(e) => {
                                    handleChangeFormData("title", e.target.value);
                                }}
                            />
                            {
                                errors.title &&
                                <BasicFormHelperText>
                                    {errors.title}
                                </BasicFormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <BasicFormLabel className={titleText}>
                                Pipeline
                            </BasicFormLabel>

                            <BasicSelect
                                options={pipelines}
                                defaultText="Select pipeline"
                                value={formData.user_pipeline_id}
                                onChange={handleSelectPipeline}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <BasicFormLabel className={titleText}>
                                Deal Stage
                            </BasicFormLabel>

                            <BasicSelect
                                options={dealStages}
                                mapping={{
                                    label: "label",
                                    value: "value"
                                }}
                                defaultText="Select deal stage"
                                value={formData.user_stage_id}
                                onChange={handleSelectStage}
                                disabled={!formData?.user_pipeline_id || !dealStages[0]}
                            />
                            {
                                errors.user_stage_id &&
                                <BasicFormHelperText>
                                    {errors.user_stage_id}
                                </BasicFormHelperText>
                            }
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: "4px", padding: "0px 8px" }}>
                            <Grid item xs={6}>
                                <BasicFormLabel className={titleText}>
                                Total Premium
                                </BasicFormLabel>

                                <div className="productValueInputWrapper">
                                    <span style={{color:`${!formData.deal_value ? placeholderColor : activeColor}`}} 
                                    className={productValueSign}>$</span>
                                    <BasicInput
                                        fullWidth
                                        value={formData.deal_value}
                                        type="text"
                                        style={{color:`${!formData.deal_value ? placeholderColor : activeColor}`}}
                                        onChange={(e) => {
                                            handleChangeFormData("deal_value", e.target.value);
                                        }}
                                    />
                                </div>

                                {
                                    errors.deal_value &&
                                    <BasicFormHelperText>
                                        {errors.deal_value}
                                    </BasicFormHelperText>
                                }
                            </Grid>

                            <Grid item xs={6}>
                                <BasicFormLabel className={titleText}>
                                    Estimated Close Date
                                </BasicFormLabel>
                                 
                                <BasicInput
                                    fullWidth
                                    value={formData.estimate_closing_date}
                                    style={{color:`${(
                                    formData.estimate_closing_date===todayDate || 
                                    !formData.estimate_closing_date ) ?  
                                    placeholderColor : activeColor }`
                                    }}
                                    type="date"
                                    onChange={(e) => {
                                        handleChangeFormData("estimate_closing_date", e.target.value);
                                    }}
                                />
                                {
                                    errors.estimate_closing_date &&
                                    <BasicFormHelperText>
                                        {errors.estimate_closing_date}
                                    </BasicFormHelperText>
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: "25px" }}>
                            <CustomTitleAddDeal
                                title={"Lines of business associated with the deal:"}
                            />
                        </Grid>

                        <Grid container style={{ marginTop: "4px", padding: "0px 8px" }}>
                            {
                                formData.user_contact_stage_products[0] &&
                                <Fragment>
                                    <Grid item xs={9} style={{ textAlign: "center" }}>
                                        <BasicFormLabel className={titleText}>
                                            Policy
                                        </BasicFormLabel>
                                    </Grid>

                                    <Grid item xs={3} style={{ textAlign: "center" }}>
                                        <BasicFormLabel className={titleText}>
                                            Value
                                        </BasicFormLabel>
                                    </Grid>
                                </Fragment>
                            }

                            {
                                formData.user_contact_stage_products.map((product, index) => (
                                    <Grid container spacing={2} className="productItem" key={index}>
                                        <Grid item xs={9}>
                                            <BasicSelect
                                                options={userProducts}
                                                mapping={{
                                                    label: "title",
                                                    value: "id"
                                                }}
                                                deletedMapping={{
                                                    field: "status",
                                                    value: status.TRASH
                                                }}
                                                defaultText="Select policy"
                                                value={product.user_product_id}
                                                onChange={(e) => {
                                                    handleProductChange(index, "user_product_id", e.target.value);
                                                }}
                                                selectedOption={(option) => {
                                                    handleProductChange(index, "value", option.unit_price);
                                                }}
                                            />
                                            {
                                                errors.user_contact_stage_products[index]?.user_product_id &&
                                                <BasicFormHelperText>
                                                    {errors.user_contact_stage_products[index].user_product_id}
                                                </BasicFormHelperText>
                                            }
                                        </Grid>

                                        <Grid item xs={3}>
                                            <div className="productValueWrapper">
                                                <div className="productValueInputWrapper">
                                                    <span style={{color:`${!product.value ?  placeholderColor : activeColor}`}} 
                                                    className={productValueSign}>$</span>
                                                    <BasicInput
                                                        value={product.value}
                                                        style={{color:`${!product.value ?  placeholderColor : activeColor}`}} 
                                                        disabled={product.user_product_id === '' ? true : false}
                                                        fullWidth
                                                        onChange={(e) => {
                                                            handleProductChange(index, "value", e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        handleDeleteProduct(index);
                                                    }}
                                                >
                                                    <DeleteForeverIcon color={"error"} />
                                                </IconButton>
                                            </div>
                                            {
                                                errors.user_contact_stage_products[index]?.value &&
                                                <BasicFormHelperText>
                                                    {errors.user_contact_stage_products[index].value}
                                                </BasicFormHelperText>
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }

                            <Grid item xs={12}>
                                <BasicAddButton
                                    startIcon={<AddIcon />}
                                    onClick={handleAddProduct}
                                >
                                    Add New Item
                                </BasicAddButton>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </ContentWrapper>
    );
}

export default GlobalAddDealModalV3ForPolicy;