import { useContext, useEffect, useState } from 'react';
import ApiList from '../assets/ApiList';
import SendEmailHelper from '../assets/Helper';
import { withOutCustomsFields } from '../components/personalized/const';
import LinkFile from "../LinkFile";
import { handleLoading, storeContactLastVn, storePersonalized, storeProps, storeVnList, updateSelectedVn, updateSendingFlag } from '../reducers/Action';
import { SmsSendContext } from '../reducers/GlobalReduers';
import Message from './Message';
import QuickReplies from './QuickReplies';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FromNumber from './FromNumber';
import TimeSetting from '../components/TimeSettings';
import moment from 'moment'
import ModalComponent from '../components/modal/ModalComponent';
import UnicodeAlert from './alert-popup/UnicodeAlert';
import GlobalContactRelatedContactSelector
    from "../../../GlobalContactRelatedContactSelector/GlobalContactRelatedContactSelector";
import Utils, { checkScamMessage } from '../../../../helpers/Utils';

const SendModule = (props) => {
    const { states, dispatch } = useContext(SmsSendContext)

    /* time setting data */
    const [sendTime, setSendTime] = useState('1') //1 = immediate, 2 = custom
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(new Date())

    /* for fetching data on scroll */
    const [scrolling, setScrolling] = useState(false);
    const [fetchingMore, setFetchingMore] = useState(false);
    const [needToLoad, setNeedToLoad] = useState(false);

    const [isShowAlert, setIsShowAlert] = useState(false)
    let currentScrollTopValue = 0;
    /* end */

    useEffect(() => {
        dispatch(storeProps({
            contactId: props.id,
            from: props.from,
            onClose: props.onClose,
            contactInfo: props.contactInfo,
            sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
            relationSuperContactId: props.relationSuperContactId
        }))
        if (props.id === null) {
            // if(props.contactInfo.PhoneNumbers!== undefined && props.contactInfo.PhoneNumbers!== null && props.contactInfo.CellPhones
            // ){
            let number = ""
            if (props.contactInfo.PhoneNumbers !== undefined && Array.isArray(props.contactInfo.PhoneNumbers) && props.contactInfo.PhoneNumbers.length > 0) {
                number = SendEmailHelper.isEmpty(props.contactInfo.PhoneNumbers[0].PhoneNumber) ? "" : props.contactInfo.PhoneNumbers[0].PhoneNumber
            }
            else if (props.contactInfo.CellPhones !== undefined && Array.isArray(props.contactInfo.CellPhones) && props.contactInfo.CellPhones.length > 0) {
                number = SendEmailHelper.isEmpty(props.contactInfo.CellPhones[0].PhoneNumber) ? "" : props.contactInfo.CellPhones[0].PhoneNumber
            }

            if (number !== "") {
                getUserVnList()
                dispatch(handleLoading(false))
            } else {
                dispatch(handleLoading({
                    isLoading: false,
                    wentWrong: true,
                    errorText: 'No number found for this contact'
                }))
            }
            // }
            // else{
            //     dispatch(handleLoading({
            //         isLoading: false, 
            //         wentWrong: true,
            //         errorText: 'No number found for this contact'
            //     }))
            // }
        }
        else {
            getContactInboxThread();
        }
        // getPersonalized();     
        return () => {
            dispatch(storeProps({
                contactId: null,
                from: '',
                onClose: null,
                sendSuccessCallBack: null,
                primaryEmail: ''
            }))
            dispatch(handleLoading(true))
        }
    }, [])

    const getContactInboxThread = () => {
        ApiList.getContactInboxThread({ contact_id: props.id }).then(res => {
            let response = res.data;
            if (response.status === 'success') {
                dispatch(storeContactLastVn({
                    id: response.last_vn_id,
                    data: response.last_vn
                }))
                dispatch(updateSelectedVn(response.last_vn_id))
            }
            getUserVnList(response);
        })
            .finally(res => {
                dispatch(handleLoading(false))
            })
            .catch(err => {
                dispatch(handleLoading(false))
            })
    }

    const getPersonalized = () => {

        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: true,
            field_name: ['id', 'title'],
            for_drop_down: true,
            drop_down_field: {
                value_field: 'id',
                title_field: 'title'
            }
        }).then(res => {

            let data = [];

            let personalizedDatas = [];

            res.data.personalized_field.forEach((row) => {
                personalizedDatas.push({
                    label: row.title,
                    value: row.personalize_tag,
                });
            });

            data = withOutCustomsFields.concat(personalizedDatas);

            let customFieldDatas = [];

            customFieldDatas.push({
                value: 'blue',
                label: 'Custom Fields',
                color: '#cdcdcd',
                isDisabled: true
            });

            res.data.custom_field.forEach((row) => {
                customFieldDatas.push({
                    label: row.title,
                    value: row.personalize_tag,
                })
            })

            data = data.concat(customFieldDatas);

            dispatch(storePersonalized(data))

        })


        // ApiList.getPersonalizedTag().then(res => {
        //     let data = [];
        //     let customFields = [];
        //     res.data.data.forEach((row) => {
        //         customFields.push({
        //             label : row.title,
        //             value : row.personalize_tag,
        //         });
        //     });
        //     data  = withOutCustomsFields.concat(customFields);

        //     let personalizedFields = [];
        //     personalizedFields.push({
        //         value: 'blue',
        //         label: 'User Customize Fields',
        //         color: '#cdcdcd',
        //         isDisabled: true
        //     });
        //     res.data.personalizedData.forEach((row) => {
        //         personalizedFields.push({
        //             label : row.title,
        //             value : row.personalize_tag,
        //         });
        //     });
        //     data  = data.concat(personalizedFields);
        //     dispatch(storePersonalized(data))
        // })
    }

    const getUserVnList = (vn_res = null) => {
        ApiList.getUserVirtualNumber().then(res => {
            let response = res.data;
            if (response.virtual_number !== undefined && response.virtual_number !== null) {
                dispatch(storeVnList(response.virtual_number))
                if (vn_res != null) {
                    if (vn_res.status === 'success') {
                        let check = false;
                        let tmpIndex;
                        response.virtual_number.forEach((item, index) => {
                            if (item.value == vn_res.last_vn_id) {
                                check = true
                                tmpIndex = index
                            }
                        })
                        if (!check) {
                            if (response.virtual_number.length > 0) {
                                dispatch(storeContactLastVn({
                                    id: response.virtual_number[0].value,
                                    data: response.virtual_number[0]
                                }))
                                dispatch(updateSelectedVn(response.virtual_number[0].value))
                            }
                            else {
                                dispatch(storeContactLastVn({
                                    id: null,
                                    data: null
                                }))
                                // dispatch(updateSelectedVn(vn_res.last_vn_id))
                            }

                        } else if (tmpIndex) {
                            dispatch(storeContactLastVn({
                                id: response.virtual_number[tmpIndex].value,
                                data: response.virtual_number[tmpIndex]
                            }))
                            dispatch(updateSelectedVn(response.virtual_number[tmpIndex].value))
                        } else {
                            if (response.virtual_number.length > 0) {
                                dispatch(storeContactLastVn({
                                    id: response.virtual_number[0].value,
                                    data: response.virtual_number[0]
                                }))
                                dispatch(updateSelectedVn(response.virtual_number[0].value))
                            }
                        }
                    } else {
                        if (response.virtual_number.length > 0) {
                            dispatch(storeContactLastVn({
                                id: response.virtual_number[0].value,
                                data: response.virtual_number[0]
                            }))
                            dispatch(updateSelectedVn(response.virtual_number[0].value))
                        }
                    }
                } else {
                    if (response.virtual_number.length > 0) {
                        dispatch(storeContactLastVn({
                            id: response.virtual_number[0].value,
                            data: response.virtual_number[0]
                        }))
                        dispatch(updateSelectedVn(response.virtual_number[0].value))
                    }
                }
            }
            getPersonalized();
        }).catch(err => {
            dispatch(handleLoading({
                isLoading: false,
                wentWrong: true,
                errorText: 'Something went wrong'
            }))
        })
    }

    const renderMainView = () => {
        if (states.wentWrong) {
            return (
                <div className="g-send-sms-error-content">
                    <span>{states.errorText}</span>
                </div>
            )
        }
        if (states.isShowTemplates) {
            return (
                <QuickReplies scrolling={scrolling} updateScrollOption={updateScrollOption} />
            )
        }
        return (
            <div className="g-sms-send-content awesome__scroll_bar">
                <div className="g-from-sms-subject-content">
                    <FromNumber />
                </div>
                {
                    !states.relationSuperContactId &&
                    <GlobalContactRelatedContactSelector
                        data={{
                            contactId: states.contactId,
                            type: "number",
                            callback: (resp) => {
                                dispatch(storeProps({
                                    relatedContactIds: resp.contactIds,
                                    contactId: props.id,
                                    from: props.from,
                                    onClose: props.onClose,
                                    contactInfo: props.contactInfo,
                                    sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
                                    relationSuperContactId: props.relationSuperContactId
                                }))
                            },
                            contactIds: states.relatedContactIds
                        }}
                    />
                }
                <Message />
                <div className="gloabal__send__module__time__setting__container">
                    <TimeSetting
                        handleTimesetting={handleTimesetting}
                    />
                </div>
            </div>
        )
    }

    const handleSendConfirm = (e) => {
        e.preventDefault();
        setIsShowAlert(false)
        if (SendEmailHelper.getAccountData('starterPack')) {
            handleShowNotification("Sorry!!!, To use this feature please upgrade your package")
            return false;
        }

        if (states.sending) {
            return
        }

        if (states.selctedVn === '') {
            handleShowNotification("No virtual number selected!")
            return
        }

        if (states.message === '') {
            handleShowNotification("Message is required!")
            return
        }

        if (Utils.getAccountData('trialRemainingDays') !== -1) {
            if (checkScamMessage(states.message)) {
                handleShowNotification("Your message was not sent because it was flagged as spam. Please review your message content and try again. Once you become a regular member, our spam checks will be less frequent.")
                return;
            }
        }

        let formData = new FormData();
        formData.append('contact_id', states.contactId);
        formData.append('origin', 3);
        formData.append('messageLevel', 'reply');
        formData.append('save_as_template', states.saveAsTemplate);
        formData.append('saveTemplate', states.saveAsTemplate);
        formData.append('saveTemplateTitle', states.templateTitle);
        formData.append('virtual_number_id', states.selctedVn);
        // formData.append('message', states.message);
        if (states.appendUnsubText) {
            formData.append('message', (states.message + "\r\nReply STOP to unsubscribe."));
        }
        else {
            formData.append('message', states.message);
        }

        if (states.file_data !== null) {
            formData.append('qqfile', states.file_data);
        }
        if (states.selectedMmsFromTemplate) {
            formData.append('file_path', states.file_url);
            formData.append('message_type', 2);
            formData.append('content', 5);
        }

        formData.append('schedule_type', sendTime);
        formData.append('date', sendTime == 2 ? moment(date).format('YYYY-MM-DD') : null);
        formData.append('time', sendTime == 2 ? moment(time).format('HH:mm') : null);

        if (states.relationSuperContactId) {
            formData.append('relation_super_contact_id', states.relationSuperContactId);
        }

        if (states.relatedContactIds && states.relatedContactIds[0]) {
            states.relatedContactIds.forEach((elem, index) => {
                formData.append(`related_contact_ids[${index}]`, elem);
            });
        }

        if (states.contactId == null) {
            formData.append('contact_info', JSON.stringify(states.contactInfo));
            dispatch(updateSendingFlag(true));

            ApiList.sendSmsFromMap(formData, true).then(res => {
                let response = res.data
                if (response.status == "error") {
                    handleShowNotification(response.html)
                }
                else {
                    handleShowNotification(response.html, 'SUCCESS')
                    if (states.sendSuccessCallBack !== undefined && states.sendSuccessCallBack !== null) {
                        states.sendSuccessCallBack(response)
                    }
                    if (states.onClose !== undefined && states.onClose !== null) {
                        states.onClose()
                    }
                }
            }).finally((res) => {
                dispatch(updateSendingFlag(false))
            })
        }
        else {
            dispatch(updateSendingFlag(true))
            ApiList.sendSms(formData, true).then(res => {
                let response = res.data
                if (response.status === "error") {
                    if (response.html === "[HTTP 400] Unable to create record: Attempt to send to unsubscribed recipient") {
                        handleShowNotification("Attempt to send to unsubscribed recipient !")
                    } else {
                        handleShowNotification(response.html)
                    }

                }
                else {
                    handleShowNotification(response.html, 'SUCCESS')
                    if (states.sendSuccessCallBack !== undefined && states.sendSuccessCallBack !== null) {
                        states.sendSuccessCallBack(response)
                    }
                    if (states.onClose !== undefined && states.onClose !== null) {
                        states.onClose()
                    }
                }
            }).finally((res) => {
                dispatch(updateSendingFlag(false))
            })
        }
    }

    const handleShowNotification = (message, type = "ERROR") => {
        if (window.showNotification !== undefined) {
            window.showNotification(type, message);
        }
    }

    const handleTimesetting = (type, value) => {
        if (type == 'date') {
            setDate(value)
        }
        else if (type == 'time') {
            setTime(value)
        }
        else if (type == 'tab') {
            setSendTime(value)
        }
    }

    /* for scroll start */
    const handleOnScroll = () => {
        if (fetchingMore) return false;

        let container = document.getElementById("modal_sms__body___container___id");
        if (container.scrollTop > currentScrollTopValue) {
            let innrerHeight = container.clientHeight;
            let dif = container.scrollHeight - (innrerHeight + container.scrollTop);
            if (dif < 100 && needToLoad) {
                setScrolling(true)
            }
            else {
                setScrolling(false)
            }
        }
        currentScrollTopValue = container.scrollTop;
    }

    const updateScrollOption = (field, value) => {
        if (field === 'fetchingMore') {
            setFetchingMore(value)
        } else if (field === 'needToLoad') {
            setNeedToLoad(value)
        }
        setScrolling(false)
    }
    /* for scroll end */

    /* for unicode alert */
    const handleEdit = () => {
        setIsShowAlert(false)
    }
    const checkHasUniCode = (text) => {
        // eslint-disable-next-line no-control-regex
        if (!text.length) return false;
        if (text.charCodeAt(0) > 255) return true;
        for (var i = 0, n = text.length; i < n; i++) {
            if (text.charCodeAt(i) > 255) {
                return true;
            }
            // eslint-disable-next-line no-control-regex
            let regex = /[^\u0000-\u00FF]/;
            const codePoint = text.codePointAt(i);
            const hexCodePoint = codePoint.toString(16);
            let testing = toUTF16(hexCodePoint)
            if (regex.test(testing)) {
                return true
            }
        }
        return false;
    }
    function toUTF16(codePoint) {
        var TEN_BITS = parseInt('1111111111', 2);
        function u(codeUnit) {
            return '\\u' + codeUnit.toString(16).toUpperCase();
        }

        if (codePoint <= 0xFFFF) {
            return u(codePoint);
        }
        codePoint -= 0x10000;

        // Shift right to get to most significant 10 bits
        var leadingSurrogate = 0xD800 | (codePoint >> 10);

        // Mask to get least significant 10 bits
        var trailingSurrogate = 0xDC00 | (codePoint & TEN_BITS);

        return u(leadingSurrogate) + u(trailingSurrogate);
        // return u(leadingSurrogate);
    }
    const handleSend = (e) => {
        /* check unicode */
        const hasUniCode = checkHasUniCode(states.message.trim())
        if (hasUniCode) {
            setIsShowAlert(true)
        }
        else {
            handleSendConfirm(e)
        }
    }

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <LinkFile.modal
            bodyId={'modal_sms__body___container___id'}
            open={props.open}
            title={"SMS Send"}
            onClose={props.onClose}
            buttonText={states.sending ? 'Sending' : 'Send'}
            buttonIcon={states.sending ? <MoreHorizIcon /> : <SendIcon />}
            onSubmit={handleSend}
            handleSubmit={handleSend}
            className="global___sms_send_modal__alt global-medium-modal"
            // extra_props_active={true}
            handleOnScroll={handleOnScroll}
        >
            <div className={`g-sms-send-module ${states.isLoading ? 'isGlobalLoading' : ''}`}>
                {states.isLoading ?
                    <div className="global_loader_parent g-loading-content">Please wait...</div>
                    :
                    renderMainView()
                }
            </div>
            <ModalComponent
                isShow={isShowAlert}
                component={<UnicodeAlert handleEdit={handleEdit} handleSend={handleSendConfirm} />}
                blockBackdrop={true}
                style={{
                    width: '590px',
                    height: '390px'
                }}
            />
        </LinkFile.modal>
    )
}
export default SendModule;