import React, {useState, useEffect} from "react";
import {
    clearDialNumber,
    addDialNumber
} from "../../../actions/globalDialerAction";
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import BasicInput from "../../custom/BasicInput";

const NumberPad = props => {
    const [dialNumber, setDialNumber] = useState(props.dialNumber);

    useEffect(() => {
        setDialNumber(props.dialNumber);
    }, [props.dialNumber]);

    const clearDialNumber = () => {
        if (dialNumber.length) {
            props.clearDialNumber();
        }
    }

    const onChangeHandle = (e) =>{
        props.addDialNumber(e.target.value);
    }

    const checkKeyPressed = (e) =>{
        if (e.key === 'Enter') {
            props.initiateDial();
        }
    }

    const addSingleNumber = (e,number) => {
        e.preventDefault();
        props.addDialNumber(dialNumber+number);
    }

    return (
        <React.Fragment>
            <div className="dial-input-wrapper" >
            <BasicInput fullWidth   value={Utils.formatNumber(dialNumber)} onChange={onChangeHandle}  placeholder="Phone Number" onKeyPress={checkKeyPressed}/>
                {/* <input autoFocus type="text" placeholder="Phone Number" value={Utils.formatNumber(dialNumber)} onChange={onChangeHandle} onKeyPress={checkKeyPressed}/> */}
                <span style={{cursor: 'pointer'}} onClick={ (e) => {e.preventDefault();clearDialNumber()}}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.8542 12.2483L12.2461 10.8555L5.14458 3.74929L3.75267 5.14211L10.8542 12.2483Z"
                            fill="white"/>
                        <path
                            d="M5.14458 12.2507L12.2461 5.14453L10.8542 3.75171L3.75267 10.8579L5.14458 12.2507Z"
                            fill="white"/>
                    </svg>
                </span>
            </div>
            <div className="numbers-pad tabs-content">
                <div className="active" id="contact-number-pad">
                    <div className="pad-number">
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => addSingleNumber(e,1)} href="#!">1</a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,2)} href="#!">2 <span>ABC</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,3)} href="#!">3 <span>DEF</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,4)} href="#!">4 <span>GHI</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,5)} href="#!">5 <span>JKL</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,6)} href="#!">6 <span>MNO</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,7)} href="#!">7 <span>PQRS</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,8)} href="#!">8 <span>TUV</span></a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,9)} href="#!">9 <span>WXYZ</span></a>
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => addSingleNumber(e,'*')} href="#!">*</a>
                        <a className="btn" onClick={(e) => addSingleNumber(e,0)} href="#!">0 <span>+</span></a>
                        <a className="btn d-flex justify-content-center align-items-center" onClick={(e) => addSingleNumber(e,'#')} href="#!">#</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        dialNumber: state.globalDialerReducer.dialNumber
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearDialNumber: () => dispatch(clearDialNumber()),
        addDialNumber: (dialNumber) => dispatch(addDialNumber(dialNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberPad);