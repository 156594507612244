import { InputBase, withStyles } from "@material-ui/core";
 const BasicInputForSearch = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
        "& .MuiInputAdornment-positionEnd": {
            position: "absolute",
            right: "12px"
        },
        "& .MuiInputAdornment-positionStart": {
            position: "absolute",
            left: "-10px"
        },
      
    },
    input: {
        position: "relative",
        backgroundColor: "white !important",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px 32px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
        "&::placeholder": {
            color: "#082852 !important",
        },
    },
}))(InputBase);

export default BasicInputForSearch;