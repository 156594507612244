import React, { useState, useEffect, useRef, useCallback } from 'react';
import Form from 'react-validation/build/form';
import { getCollaboratorList, getSubUserListForContactlist, saveCollaborator } from './../../redux/contactApi';
import axis from "axis.js";
import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {connect} from 'react-redux'
import { getSubUserList, setSubUserListFromAction } from './../../redux/contactAction.js';
import GlobalModal from '../../Helpers/Modal/GlobalModal';
import BasicInput from '../../../custom/BasicInput';
import SkeletonLoader from './SkeletonLoader';

const useStyles = makeStyles({
    search: {
        padding : "0 1px 0 20px !important",
        marginRight: "0px"
    }
})


const EachUser = ({ data, isChecked, selectUser }) => (
    <li>
        <label>
            <input name="users" checked={isChecked} onChange={selectUser} type="checkbox" defaultValue={data.id} className="styled-checkbox filled-in custom__filled__in" />
            <span>{data.full_name}</span>
        </label>
    </li>
);

const ModalAddCollaboratorFormV2 = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [submitEnable, setSubmitEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitingContact, setSubmitingContact] = useState(false);
    const [allTeamUser, setAllTeamUser] = useState([]);
    const [tempTeamUser, setTempTeamUser] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allCollaborator, setAllCollaborator] = useState([]);
    const [tempCollaborator, setTempCollaborator] = useState([]);
    const classes = useStyles();
    const formRef = useRef();

    useEffect(() => {
        let users = props.selected.map(data => data.user.id);
        setSelectedUsers(users);
        loadTeamUserList();
        loadCollaboratorList();
    }, [props.selected]);

    useEffect(() => {
        setIsOpen(props.isOpen);
        if (props.selected) {
            let users = props.selected.map(data => data.user.id);
            setSelectedUsers(users);
        }
    }, [props.isOpen, props.selected]);

    const removeApiError = useCallback(() => {
        formRef.current.hideError(formRef.current.number);
    }, []);

    const loadTeamUserList = useCallback(() => {
        setLoading(true);
        if (props.subUserList != null) {
            setAllTeamUser(props.subUserList);
            setTempTeamUser(props.subUserList);
            setLoading(false);
        } else {
            getSubUserListForContactlist()
                .then(response => {
                    props.setSubUserListFromAction(response.data.data);
                    setAllTeamUser(response.data.data);
                    setTempTeamUser(response.data.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log('something is wrong' + error);
                    setLoading(false);
                });
        }
    }, [props.subUserList]);

    const loadCollaboratorList = useCallback(() => {
        setLoading(true);
        getCollaboratorList()
            .then(response => {
                setAllCollaborator(response.data.data);
                setTempCollaborator(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                console.log('something is wrong' + error);
                setLoading(false);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const params = {
            contact_id: props.contact_id,
            selectedUsers: selectedUsers
        };

        setSubmitingContact(true);
       

        saveCollaborator(params).then(res => {
            setSubmitingContact(false);
            if (res.data.status === 'error') {
                setErrorMsg(res.data.html);

                setTimeout(() => {
                    setErrorMsg("");
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                // Handle validation error
            } else {
                window.showNotification("SUCCESS", "Collaborators updated successfully")
                props.callBack();
            }
        });
    };

    const toggle = () => {
        if (props.modalToggle) {
            props.modalToggle();
        }
        setIsOpen(!isOpen);
    };

    const handelCheckBox = (e) => {
        const id = parseInt(e.target.value);
        let updatedSelectedUsers = [...selectedUsers];

        if (e.target.checked) {
            if (!updatedSelectedUsers.includes(id)) {
                updatedSelectedUsers.push(id);
            }
        } else {
            updatedSelectedUsers = updatedSelectedUsers.filter(userId => userId !== id);
        }

        setSelectedUsers(updatedSelectedUsers); 
        setSubmitEnable(true);
    };

    const changeSearch = (e) => {
        const searchKey = e.target.value.toUpperCase().trim();
        const userData = searchKey 
            ? tempCollaborator.filter(data => data.full_name.toUpperCase().includes(searchKey))
            : tempCollaborator;

        setAllCollaborator(userData);
    };

    const isUserChecked = (id) => selectedUsers.includes(parseInt(id));

    let runningList = '';
    console.log(runningList);
    let haveMember = true;
    if (!axis.isUndefined(allCollaborator)) {
        if (allCollaborator.length) {
            runningList = allCollaborator.map((data, index) => (
                <EachUser selectUser={handelCheckBox} isChecked={isUserChecked(data.id)} data={data} key={index} />
            ));
        } else {
            haveMember = false;
        }
    }

    return (
        <GlobalModal {...props}
            title={"Collaborator"}
            buttonText={"Save"}
            hideFooter={true}
            className={'manage-tag-modal modal-assign-campaign global-medium-modal'}>
            <div className="search-bar-top-area2.search-bar-top-area">
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Grid container>
                        {loading ? ( 
                             <Grid item xs={12}>
                                <SkeletonLoader /> 
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <div className={`${classes.search}`}>
                                        <BasicInput 
                                         onChange={changeSearch} 
                                         placeholder="Type and search" 
                                         fullWidth   
                                         endAdornment={
                                           <InputAdornment position="end">
                                           <SearchIcon style={{ color: "#656565" , marginRight:"10px" }} />
                                           </InputAdornment>
                                        }/>
                                    </div>
                                    <div >
                                        <ul className="awesome__scroll_bar" style={{ marginTop: '20px', marginLeft: '10px', paddingTop: '18px'}}>
                                            {(Array.isArray(runningList) ? runningList : []).map((item, index) => (
                                                <li key={index} style={{
                                                    backgroundColor: 'white',
                                                    border: '1px',
                                                    borderRadius: '4px',
                                                    padding: '10px',
                                                    marginBottom: '5px',
                                                    marginLeft: '10px'
                                                }}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>


                                </Grid>
                                <Grid item xs={12}>
                                    <div className="manage-tag-footer modal-footer">
                                        <button color="primary" 
                                            type="submit" 
                                            disabled={!submitEnable} 
                                            className="accent--bg--color modal-close waves-effect"
                                            style={{padding:'12px 24px',color:'white',borderRadius:'5px',cursor:'pointer',display:'flex',alignItems:'center',gap:'4px',fontWeight:500,fontSize:'16px'}}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="10" cy="10" r="10" fill="white"/>
                                                <path d="M13.8447 9.99923C13.8447 10.3537 13.8139 10.6403 13.4594 10.6403H10.6395V13.4601C10.6395 13.814 10.353 13.8454 9.9985 13.8454C9.64401 13.8454 9.35747 13.814 9.35747 13.4601V10.6403H6.5376C6.18375 10.6403 6.15234 10.3537 6.15234 9.99923C6.15234 9.64474 6.18375 9.3582 6.5376 9.3582H9.35747V6.53833C9.35747 6.18385 9.64401 6.15308 9.9985 6.15308C10.353 6.15308 10.6395 6.18385 10.6395 6.53833V9.3582H13.4594C13.8139 9.3582 13.8447 9.64474 13.8447 9.99923Z" fill="#3C7EF3"/>
                                            </svg>
                                            Update Collaborators
                                        </button>
                                        <a href="#!" onClick={(e) => {
                                            e.preventDefault();
                                            toggle();
                                        }} className="manage-tag-footer-btn-left custom__modal--btn-close modal-close waves-effect waves-green btn-flat">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white"/>
                                            </svg>
                                            Cancel
                                        </a>
                                        </div>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Form>
            </div>
        </GlobalModal>
    );
};

const mapStateToProps = state => {
    return {
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalAddCollaboratorFormV2);
