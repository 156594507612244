import {styled} from "@material-ui/core";
import {
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)(({theme})=>({
    backgroundColor: "white !important",
    position: "relative",
    fontSize: "15px !important",
    margin: "0 !important",
    padding: " 9px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
        backgroundColor: "#fff !important"
    },
    "&::placeholder": {
        color: "#082852 !important",
    },
    "& .MuiInput-input": {
        height: "unset !important"
    },
    "& .MuiIconButton-root": {
        padding: "0px !important",
        "&:focus": {
            backgroundColor: "transparent !important"
        },
    }
}));

export const StyledKeyboardTimePicker = styled(KeyboardTimePicker)(({theme})=>({
    backgroundColor: "white !important",
    position: "relative",
    fontSize: "15px !important",
    margin: "0 !important",
    padding: " 9px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
        backgroundColor: "#fff !important"
    },
    "&::placeholder": {
        color: "#082852 !important",
    },
    "& .MuiInput-input": {
        height: "unset !important"
    },
    "& .MuiIconButton-root": {
        padding: "0px !important",
        "&:focus": {
            backgroundColor: "transparent !important"
        },
    }
}));