import {useContext, useEffect, useState} from 'react';
import ApiList from '../assets/ApiList';
import SendEmailHelper from '../assets/Helper';
import { withOutCustomsFields } from '../components/personalized/const';
import LinkFile from "../LinkFile";
import {
    handleLoading,
    storeCcMailInfo,
    storeFromEmailsInfo,
    storePersonalized,
    storeProps,
    storeToEmailsInfo,
    storeUserInfo,
    updateFromEmail,
    updateMessage,
    updateSendingFlag,
    updateSubject,
    updateToEmail,
    updateToEmailSingle
} from '../reducers/Action';
import { EmailSendContext } from '../reducers/GlobalReduers';
import FromEmail from './FromEmail';
import Message from './Message';
import QuickReplies from './QuickReplies';
import Subject from './Subject';
import ToEmail from './ToEmail';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TimeSetting from '../components/TimeSettings';
import moment from 'moment';
import {getCookie, getCookieLogin, setCookie} from "../../../../helpers/Cookie";
import {confirmAlert} from "react-confirm-alert";
import { getAdditionalContactsByContactApi } from '../../../contactDetails/redux/contactDetailApi';
import GlobalContactRelatedContactSelector
    from "../../../GlobalContactRelatedContactSelector/GlobalContactRelatedContactSelector";

const SendModule = (props) => {
    const {states, dispatch} = useContext(EmailSendContext)

    /* time setting data */
    const [sendTime, setSendTime] = useState('1') //1 = immediate, 2 = custom
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(new Date())

    /* for fetching data on scroll */
    const [scrolling, setScrolling] = useState(false);
    const [fetchingMore, setFetchingMore] = useState(false);
    const [needToLoad, setNeedToLoad] = useState(false);
    let currentScrollTopValue = 0;

    useEffect(() => {
        dispatch(storeProps({
            contactId: props.id, 
            from: props.from, 
            onClose: props.onClose,
            sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
            primaryEmail: props.primaryEmail,
            contactInfo: props.contactInfo,
            emailId: props.emailId,
            threadData: props.threadData,
            relationSuperContactId: props.relationSuperContactId
        }));

        getPersonalized();
        if(props.id === null){
            if(props.contactInfo.EmailAddress!== undefined && props.contactInfo.EmailAddress!== null && props.contactInfo.EmailAddress !== ""){
                let email = props.contactInfo.EmailAddress;
                if(email !== ""){
                    let toEmailsData = []
                    toEmailsData = [{
                        email: props.primaryEmail, 
                        label: ( props.primaryEmail + " (Primary Email)")
                    }]
                    dispatch(storeToEmailsInfo(toEmailsData))
                    dispatch(updateToEmail(toEmailsData))

                    // single to email select on primary email
                    dispatch(updateToEmailSingle({
                        email: props.primaryEmail,
                        label: ( props.primaryEmail + " (Primary Email)")
                    }));
                    getProfileData()
                    dispatch(handleLoading(false))
                }else{
                    dispatch(handleLoading({
                        isLoading: false, 
                        wentWrong: true,
                        errorText: 'No number found for this contact'
                    }))
                }
            }
            else{
                dispatch(handleLoading({
                    isLoading: false, 
                    wentWrong: true,
                    errorText: 'No number found for this contact'
                }))
            }
        }
        else{
            getAdditionalContactsByContactApi({contact_id : props.id})
            .then(res => {
                let response = res.data;
    
                if(response.length === 0 && SendEmailHelper.isEmpty(props.primaryEmail)){
                    dispatch(handleLoading({
                        wentWrong: true,
                        errorText: 'You can not send email to this contact. No email found for this contact.'
                    }))
                    return
                }
    
                let toEmailsData = []
                if(!SendEmailHelper.isEmpty(props.primaryEmail)){
                    toEmailsData = [{
                        email: props.primaryEmail, 
                        label: ( props.primaryEmail + " (Primary Email)")
                    }]
                }
                
                let selectedEmail = [...toEmailsData];
                if(response.length > 0){
                    response.map((eachMail, index) => {
                        if(eachMail.email){
                            toEmailsData.push({
                                id : eachMail.id,
                                email: eachMail.email, 
                                label : eachMail.email + " ("+eachMail.contact_relation+")"
                            })
                            if(eachMail.is_default == 1){
                                selectedEmail = [{
                                    id:eachMail.id, 
                                    email : eachMail.email, 
                                    label : eachMail.email + " ("+eachMail.contact_relation+")"
                                }];
                            }
                        }
                    })
                }
                dispatch(storeToEmailsInfo(toEmailsData))
                dispatch(updateToEmail(selectedEmail))

                let selectedToEmailId = null;
                let selectedToEmail = "";
                let selectedToEmailLabel = "";

                if(props.primaryEmail){
                    selectedToEmail = props.primaryEmail;
                    selectedToEmailLabel = "Primary Email";
                }else if (!props.primaryEmail && Array.isArray(response) && response.length > 0) {
                    selectedToEmailId = response[0].id;
                    selectedToEmail = response[0].email;
                    selectedToEmailLabel = response[0].contact_relation;
                }

                // single to email select on primary email
                dispatch(updateToEmailSingle({
                    id: selectedToEmailId,
                    email: selectedToEmail,
                    label: ( selectedToEmail + " ("+selectedToEmailLabel+")")
                }));
                dispatch(updateToEmail([
                    {
                        email: selectedToEmail,
                        label: ( selectedToEmail + " ("+selectedToEmailLabel+")")
                    }
                ]));

                getProfileData(toEmailsData);
            })
            .finally(res => {
                dispatch(handleLoading(false))
            })
            .catch(error => {
                dispatch(handleLoading({
                    isLoading: false, 
                    wentWrong: true,
                    errorText: 'Something went wrong! Try again later.'
                }))
            })
        }

        return () => {
            dispatch(storeProps({
                contactId: null, 
                from: '', 
                onClose: null,
                sendSuccessCallBack: null,
                primaryEmail: '',
                emailId : null,
                threadData: null
            }))
            dispatch(handleLoading(true))
        }
    }, [])

    const getProfileData = (toEmailsData=null) => {
        ApiList.getProfileData().then(res => {
            let response = res.data
            let selectedFromEmail = ''
            if(response.campaign.length > 0){
                selectedFromEmail = response.campaign[0];
            }
            if (response.agency.email_provider !== 0 && response.profile.email_provider === 1) {
                selectedFromEmail = {
                    label: JSON.parse(response.profile.nylas_email_provider_info).email,
                    value: JSON.parse(response.profile.nylas_email_provider_info).email,
                };
            }

            if (props?.threadData?.from){
                let threadFromEmail = response.campaign.find((email)=>(email.value === props.threadData.from));
                if (threadFromEmail){
                    selectedFromEmail = threadFromEmail;
                }
            }

            if (props?.threadData?.message_subject){
                dispatch(updateSubject({
                    subject: `Re: ${props.threadData.message_subject}`,
                    validSubject: false,
                }));
            }

            dispatch(updateFromEmail(selectedFromEmail))
            dispatch(storeUserInfo({
                agency: response.agency,
                userInfo: response.profile,
                userSignature: response.profile.show_signature == 1 ? response.profile.signature : '',
                appointmentUrl: response.profile.show_appointment_url == 1 ? response.profile.appointment_url : ''
            }))
            dispatch(storeFromEmailsInfo({
                allFromEmails: response.campaign,
                nylasActive: response.profile.email_provider === 1 ? true : false, //will test this later
            }))
            
            let content = '<br />' + (response.profile.show_appointment_url == 1 ? (response.profile.appointment_url ? response.profile.appointment_url : '') : '');
            content += response.profile.show_signature == 1 ? (response.profile.signature ? response.profile.signature : '') : '';
            dispatch(updateMessage({
                message: content,
                validMessage: false,
            }))

            let tempEmail = [];

            if (toEmailsData){
                toEmailsData.map((email, index)=>{
                    if (email.id !== undefined && (props.primaryEmail ? true : index !== 0)){
                        tempEmail.push(email.email);
                    }
                });
            }

            let ccMails = [];
            ccMails.push(response.profile.email);

            if (Array.isArray(tempEmail) && tempEmail.length > 0){
                dispatch(storeCcMailInfo({
                    defaultCc: ccMails,
                    ccMails: [...ccMails, ...tempEmail],
                    activeCc: true
                }));
            }else {
                dispatch(storeCcMailInfo({
                    defaultCc: ccMails,
                    ccMails: ccMails,
                }));
            }
        })
        .catch(error => {
            dispatch(handleLoading({
                isLoading: false, 
                wentWrong: true,
                errorText: 'Something went wrong! Try again later.'
            }))
        })
    }

    const getPersonalized = () => {
        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: true,
            field_name: ['id','title'],
            for_drop_down: true,
            drop_down_field: {
            value_field: 'id',
            title_field: 'title'
            }
            }).then(res => {

                let data = [];
                let personalizedDatas = [];
                
                res.data.personalized_field.forEach((row) => {
                    personalizedDatas.push({
                        label : row.title,
                        value : row.personalize_tag,
                    });
                });
    
                data  = withOutCustomsFields.concat(personalizedDatas);
    
                let customFieldDatas = [];
    
                customFieldDatas.push({
                    value: 'blue',
                    label: 'Custom Fields',
                    color: '#cdcdcd',
                    isDisabled: true
                });
                
                res.data.custom_field.forEach((row)=>{
                    customFieldDatas.push({
                        label : row.title,
                        value : row.personalize_tag,
                    })
                })
    
                data  = data.concat(customFieldDatas);
              
                dispatch(storePersonalized(data))

            })

        // ApiList.getPersonalizedTag().then(res => {
        //     let data = [];
        //     let customFields = [];
        //     res.data.data.forEach((row) => {
        //         customFields.push({
        //             label : row.title,
        //             value : row.personalize_tag,
        //         });
        //     });
        //     data  = withOutCustomsFields.concat(customFields);

        //     let personalizedFields = [];
        //     personalizedFields.push({
        //         value: 'blue',
        //         label: 'User Customize Fields',
        //         color: '#cdcdcd',
        //         isDisabled: true
        //     });
        //     res.data.personalizedData.forEach((row) => {
        //         personalizedFields.push({
        //             label : row.title,
        //             value : row.personalize_tag,
        //         });
        //     });
        //     data  = data.concat(personalizedFields);
        //     dispatch(storePersonalized(data))
        // })
    }

    const renderMainView = () => {
        if(states.wentWrong){
            return(
                <div className="g-send-email-error-content">
                    <span>{states.errorText}</span>
                </div>
            )
        }
        if(states.isOpenQuickReply){
            return(
                <QuickReplies scrolling={scrolling} updateScrollOption={updateScrollOption} />
            )
        }
        return(
            <div className="g-email-send-content awesome__scroll_bar-1">
                {/* to email */}
                <ToEmail />
                {/* from email and subject */}
                <div className="g-from-email-subject-content">
                    <FromEmail />
                    <Subject />
                </div>
                {
                    !states.relationSuperContactId &&
                    <GlobalContactRelatedContactSelector
                        data={{
                            contactId: states.contactId,
                            type: "email",
                            callback: (resp) => {
                                dispatch(storeProps({
                                    relatedContactIds: resp.contactIds,
                                    contactId: props.id,
                                    from: props.from,
                                    onClose: props.onClose,
                                    sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
                                    primaryEmail: props.primaryEmail,
                                    contactInfo: props.contactInfo,
                                    emailId: props.emailId,
                                    threadData: props.threadData,
                                    relationSuperContactId: props.relationSuperContactId
                                }))
                            },
                            contactIds: states.relatedContactIds
                        }}
                    />
                }
                {/* message */}
                <Message />
                <TimeSetting handleTimesetting={handleTimesetting} />
            </div>
        )
    }

    const removeFirstRe = (input) => {
        return input.replace(/^Re: /, "");
    }

    const handleSend = (e) => {
        e.preventDefault();
        if(SendEmailHelper.getAccountData('starterPack')){
            handleShowNotification("Sorry!!!, To use this feature please upgrade your package")
            return false;
        }

        if(states.sending){
            return
        }
        if(states.selectedToEmails.length === 0){
            handleShowNotification("No to email selected!")
            return
        }

        if(states.selectedFromEmail === ''){
            handleShowNotification("No from email selected!")
            return
        }

        if(states.subject === ''){
            handleShowNotification("Subject is required!")
            return
        }

        if(states.message === ''){
            handleShowNotification("Message is required!")
            return
        }

        let lists = states.attachmentFiles;
        let finalList = [];
        lists.forEach(function(list) {
            let name = list.name;
            if(  name !== undefined){
                finalList.push({
                    'file_name': list.file_original_name !== undefined ? list.file_original_name : name,
                    'file_url':name
                })
            }
        });

        let fromEmail = states.selectedFromEmail;
        if(typeof states.selectedFromEmail === 'object'){
            fromEmail = states.selectedFromEmail.value
        }

        let contactEmailId = null;
        if(typeof states.selectedToEmail === 'object'){
            contactEmailId = states.selectedToEmail.id;
        }

        let subject = states.threadData ? removeFirstRe(states.subject) : states.subject;

        let formData = {
            contact_id: states.contactId,
            origin: 3,
            message: states.message,
            messageLevel: 'email',
            subject: subject,
            email: fromEmail,
            contact_email_id: contactEmailId  ? [contactEmailId] : null,
            // cc: [],
            // bcc: [],
            cc : (states.activeCc === false) ? [] : states.ccMails.join(', '),
            bcc : (states.activeBcc  === false)? [] : states.bccMails.join(', '),
            attached: finalList,
            schedule_type: sendTime,
            date: (sendTime == 2 ? moment(date).format('YYYY-MM-DD') : null),
            time: (sendTime == 2 ? moment(time).format('HH:mm') : null),
            save_as_template: states.saveAsTemplate,
            saveTemplate: states.saveAsTemplate,
            saveTemplateTitle: states.saveTemplateTitle,
        }

        if (states.relationSuperContactId){
            formData.relation_super_contact_id = states.relationSuperContactId;
        }

        if (states.relatedContactIds && states.relatedContactIds[0]){
            formData.related_contact_ids = states.relatedContactIds;
        }

        if(states.contactId === null){
            formData['contact_info'] = JSON.stringify(states.contactInfo)
            dispatch(updateSendingFlag(true));

            ApiList.sendSmsFromMap(formData).then(res => {
                let response = res.data
                if(response.status == "error"){
                    handleShowNotification(response.html)
                }
                else{
                    handleShowNotification(response.html, 'SUCCESS')
                    if(states.sendSuccessCallBack !== undefined && states.sendSuccessCallBack !== null){
                        states.sendSuccessCallBack(response)
                    }
                    if(states.onClose !== undefined && states.onClose !== null){
                        states.onClose()
                    }
                }
            }).finally((res) => {
                dispatch(updateSendingFlag(false))
            })
        }
        else{

            dispatch(updateSendingFlag(true))
            ApiList.sendEmail(formData).then(res => {
                let response = res.data
                if(response.status === "error"){
                    handleShowNotification(response.html);

                    if(response.nylas_status_code !== undefined){
                        let nylasCredentialError = 401;
                        let nylasAuthError = 403;
                        if(response.nylas_status_code == nylasCredentialError || response.nylas_status_code == nylasAuthError){
                            let accountData = null;
                            let check_new = getCookie("encode__process__new")
                            if(check_new !== undefined){
                                accountData = JSON.parse(getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA));
                            }else{
                                accountData = JSON.parse(getCookie(process.env.REACT_APP_ACCOUNT_DATA));
                            }
                            accountData['userOnboard'] = true;
                            setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);

                            confirmAlert({
                                title: 'Whoops!',
                                message: response.html,
                                buttons: [
                                    {
                                        label: 'Reconnect Email',
                                        onClick: () => {
                                            setTimeout(
                                                window.location.replace('/onboard')
                                                , 2000);
                                        }
                                    }
                                ]
                            });




                        }
                    }
                }
                else{
                    handleShowNotification(response.html, 'SUCCESS')
                    if(states.sendSuccessCallBack !== undefined && states.sendSuccessCallBack !== null){
                        states.sendSuccessCallBack(response)
                    }
                    if(states.onClose !== undefined && states.onClose !== null){
                        states.onClose()
                    }
                }
            }).finally((res) => {
                dispatch(updateSendingFlag(false))
            })
        }
    }

    const handleShowNotification = (message, type="ERROR") => {
        if(window.showNotification !== undefined){
            window.showNotification(type, message);
        }
    }

    const handleTimesetting = (type, value) => {
        if(type === 'date'){
            setDate(value)
        }
        else if(type === 'time'){
            setTime(value)
        }
        else if(type === 'tab'){
            setSendTime(value)
        }
    }

    const handleOnScroll = () => {
        if(fetchingMore)return false;
        
        let container = document.getElementById("modal_body__email_send_modal");
        if(container.scrollTop > currentScrollTopValue){
            let innrerHeight = container.clientHeight;
            let dif = container.scrollHeight - (innrerHeight + container.scrollTop);
            if ( dif < 100 && needToLoad) {
                setScrolling(true)
            }
            else{
                setScrolling(false)
            }
        }
        currentScrollTopValue = container.scrollTop;
    }

    const updateScrollOption = (field, value) => {
        if(field === 'fetchingMore'){
            setFetchingMore(value)
        }else if(field === 'needToLoad'){
            setNeedToLoad(value)
        }
        setScrolling(false)
    }
    
    return(
        // eslint-disable-next-line react/jsx-pascal-case
        <LinkFile.modal
            bodyId={'modal_body__email_send_modal'}
            open={props.open}
            title={props.threadData ? "Reply Email" : "Email Send"}
            onClose={props.onClose}
            buttonText={states.sending ? 'Sending' : 'Send'}
            buttonIcon={states.sending ? <MoreHorizIcon /> : <SendIcon />}
            onSubmit={handleSend}
            handleSubmit={handleSend}
			className="ib__inbox_email_send_modal global-medium-modal"
            extra_props_active={true}
            handleOnScroll={handleOnScroll}
        >
            <div className={`g-email-send-module ${ states.isLoading ? 'isGlobalLoading' : ''}`}>
                {/* main view */}
                {states.isLoading ? 
                    <div className="global_loader_parent g-loading-content">Please wait...</div>
                :
                    renderMainView()
                }
            </div>
        </LinkFile.modal>
    )
}
export default SendModule;

/* 
// cc mail 
 const addCcMail = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) {
        if(e.target.value == ''){
            setToMaildata({...toMaildata, isCcValid: false, showCcValidMessage: 'Enter a mail address to add CC'})
        }
        else if(!Utils.validateEmail(e.target.value)){
            setToMaildata({...toMaildata, isCcValid: false, showCcValidMessage: 'Enter a valid mail address to add CC'})
        }
        else if(toMaildata.ccMails.includes(e.target.value)){
            // mail check in array
            setToMaildata({...toMaildata, isCcValid: false, showCcValidMessage: 'This mail already added in CC'})
        }
        else{
            let newData = toMaildata.ccMails;
            newData.push(e.target.value)
            setToMaildata({...toMaildata, ccMails: newData, textToAddCc: ''})
        }
    }
}

const addCcMailChange = (event) => {
    setToMaildata({...toMaildata, textToAddCc: event.target.value,  isCcValid: true, showCcValidMessage: ''})
}

const removeFromCc = (item, index) => {
    const oldData = toMaildata.ccMails;
    oldData.splice(index, 1)
    setToMaildata({...toMaildata, ccMails: oldData})
}

// bcc mail
const addBccMail = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) {
        if(e.target.value == ''){
            setToMaildata({...toMaildata, isBccValid: false, showBccValidMessage: 'Enter a mail address to add BCC'})
        }
        else if(!Utils.validateEmail(e.target.value)){
            setToMaildata({...toMaildata, isBccValid: false, showBccValidMessage: 'Enter a valid mail address to add BCC'})
        }
        else if(toMaildata.bccMails.includes(e.target.value)){
            setToMaildata({...toMaildata, isBccValid: false, showBccValidMessage: 'This mail already added in BCC'})
        }
        else{
            let newData = toMaildata.bccMails;
            newData.push(e.target.value)
            setToMaildata({...toMaildata, bccMails: newData, textToAddBcc: ''})
        }
    }
}

const addBccMailChange = (event) => {
    setToMaildata({...toMaildata, textToAddBcc: event.target.value, showBccValidMessage: '', isBccValid: true})
}

const removeFromBcc = (item, index) => {
    const oldData = toMaildata.bccMails;
    oldData.splice(index, 1)
    setToMaildata({...toMaildata, bccMails: oldData})
}



*/