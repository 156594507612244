import { Box } from "@material-ui/core";
import Appointment from "./moreInfo/appointment/Appointment";
import CampaignList from "./moreInfo/campaign/CampaignList";
import Deal from "./moreInfo/deal/Deal";
import Delete from "./moreInfo/delete/Delete";
import Task from "./moreInfo/task/Task";
import Collaborators from "./moreInfo/collaborator/Collaborators";
import FormEntry from "./moreInfo/formEntry/FormEntry";
import DNC from "./moreInfo/dnc/DNC";
import LeadSource from "./moreInfo/leadSource/LeadSource";
import { connect } from "react-redux";
import { getActivityListContactDetails, getContactSmartFormEntries , getSourceListDropdown } from "../../api/contactApi";
import { fetchTasks, setContactDetails, getUserSourceList,  setUserSourceList} from "./redux/contactAction";
import { storeContactActivityList } from "../../actions/contactAction";
import {useEffect, useState} from "react";
import Notes from "../GlobalContactDetails/notes/Notes";
import ContactNotes from "../GlobalContactDetails/moreInfo/notes";
import DealV2 from "./moreInfo/deal/DealV2";
import Policy from './moreInfo/policy/Policy'
import Utils from "./Helpers/Utils";
const MoreInfo = ({
  contactDetails,
  storeContactActivityList,
  allActivityList,
  subUserList,
  collaboratorsList,


}) => {

 const [formEntries, setFormEntries] = useState([]);
 const [sourceList, setSourceList] = useState([]);

    useEffect(() => {
        sourceListDropdown();
    },[]);

  useEffect(() => {
    if (contactDetails?.id) {
      getActivityList();
      getContactForm();
    }
  }, [contactDetails]);

  const sourceListDropdown = () => {
      getSourceListDropdown().then((res)=>{
          setSourceList(res.data)
      }).catch((err) => {
          console.error('get source list ', err)
      });
  }

  const getContactForm = () => {
      getContactSmartFormEntries(contactDetails.id)
          .then((res) => {
              if (res && res.success) {
                  setFormEntries(res.data);
              } else {
                  setFormEntries([]);
              }
          }).catch((err) => {
              console.error('get form entries error ', err)
          setFormEntries([]);
      });
  }

    const getMargedEntriesAndCustomEntries = () => {

        let entries = [];

        if (Array.isArray(contactDetails?.form_entries)) {
            entries = contactDetails.form_entries;
        }

        if (Array.isArray(contactDetails?.custom_form_entries)) {
            entries = [...entries, ...contactDetails.custom_form_entries];
        }

        return entries;
    }


  const getActivityList = () => {
    getActivityListContactDetails({ contact_id: contactDetails.id })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item, index) => {
              res.data.data[index].contact = contactDetails;
            });

            storeContactActivityList([...res.data.data]);
          } else if (res.data.data.length === 0) {
            storeContactActivityList([]);
          }
        }
      })
      .catch((e) => {
        console.error('get activity list error ', e);
      });
  };


  return (
    <Box>
      {/* <Deal contactDetails={contactDetails} /> */}
      <DealV2 contactDetails={contactDetails} />

      <Task
        people={contactDetails}
        contact_id={contactDetails ? contactDetails.id : null}
        refreshData={() => getActivityList()}
        activity={allActivityList}
        from={"Task"}
        subUserList={subUserList}
        contactDetails={contactDetails}
      />

      <Appointment
        people={contactDetails}
        contact_id={contactDetails ? contactDetails.id : null}
        refreshData={() => getActivityList()}
        activity={allActivityList}
        from={"Appointment"}
        subUserList={subUserList}
        contactDetails={contactDetails}
      />
      <DNC
      // contact={contactDetails}
      // contact_id={contactDetails ? contactDetails.id : null}
      // collaborators={collaboratorsList}
      />
      {Utils.getAccountData("agencyBusinessType") &&
        Utils.getAccountData("agencyBusinessType") === "INSURANCE" && (
          <Policy />
        )}

      <Collaborators
        people={contactDetails}
        contact_id={contactDetails ? contactDetails.id : null}
        collaborators={collaboratorsList}
      />
      <ContactNotes contact_id={contactDetails ? contactDetails.id : null} />
      <CampaignList people={contactDetails} contact_id={contactDetails?.id} />
      <FormEntry
        smartFormEntries={formEntries}
        entries={getMargedEntriesAndCustomEntries()}
      />
      <LeadSource
        reloadTopSection={() => {}}
        contactDetails={contactDetails}
        contactSource={contactDetails ? contactDetails.source : null}
        sourceList={
          sourceList !== undefined && sourceList != null ? sourceList : null
        }
      />
      {/* <Delete contact_id={contactDetails ? contactDetails.id : null} /> */}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
    allActivityList: state.contactPopUpReducer.allActivityList,
    collaboratorsList: state.contactPopUpReducer.collaborators,
    subUserList: state.addContactReducer.subUserList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    storeContactActivityList: (params) => dispatch(storeContactActivityList(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfo);
